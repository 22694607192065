import { Link } from 'react-router-dom';
import teamLogo from '../../images/team-logo.png';
import championLogo from '../../images/champion.png';
function TeamCard({ data, league }) {
    const styles = {
        card: {
            width: '202px',
            height: '253px',
            background: 'var(--blue)',
            borderRadius: '12px',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            padding: '.5rem',
            boxSizing: "border-box"
        },
        imageWrapper: {
            width: '102px',
            height: '102px',
            background: 'white',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden'
        },
        btn: {
            textDecoration: 'none',
            font: '400 16px var(--fontSSP)',
            color: 'var(--blue)',
            background: 'white',
            padding: '.4rem 3.2rem',
            borderRadius: '16px'
        }
    };
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
    const word = league.toLowerCase();
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    const teamData = {
        name: data.club_name + '-' + data.team_name,
        league: capitalized
    }
    return (
        <div style={styles.card}>
            <div style={styles.imageWrapper}>
                <img src={data.team_logo ? "https://admin.btcluk.com/logo/" + data.team_logo : teamLogo} alt="" width="100%" height="100%" />
            </div>
            <p style={{ font: '800 16px var(--fontInter)', margin: 0, color: 'white', width: '100%', overflow: "hidden", textAlign: 'center' }}>{data.club_name}</p>
            <div><span style={{ font: '500 1rem var(--fontInter)', color: '#F6D330' }}>{data.team_name}</span></div>
            <Link onClick={handleScrollToTop} to={"/team-profile/" + data.club_id + "/" + data.team_id} style={styles.btn}>Profile</Link>
        </div >
    );
}

export default TeamCard;