import teamLogo from '../../images/team-logo.png';

function MemberCard() {

    const styles = {
        card:{
            width: '202px',
            height: '253px',
            background:'var(--blue)',
            borderRadius:'12px',
            display:'flex',
            flexFlow:'column',
            justifyContent: 'space-evenly',
            alignItems: 'center'
        },
        imageWrapper:{
            width:'102px',
            height:'102px',
            background:'white',
            borderRadius:'50%',
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        },
        btn:{
            textDecoration:'none',
            font:'400 16px var(--fontSSP)',
            color:'var(--blue)',
            background:'white',
            padding:'.4rem 3.2rem',
            borderRadius:'16px'
        }
    };
    return(
        <div style={styles.card}>
            <div style={styles.imageWrapper}>
                <img src={teamLogo} alt="" />
            </div>
            <p style={{font:'800 16px var(--fontInter)',margin:0,color:'white'}}>Valvai Blules CC</p>
        </div>
    );
}

export default MemberCard;