import slider from '../images/slide.png';
import { useEffect, useState } from 'react';
import Slide from './Slide';
import axios from 'axios';

function Slider() {
    let timerID;
    const [slides, setSlides] = useState('')
    const [changeBtns, setChangeBtns] = useState('');
    useEffect(() => {
        const language = localStorage.getItem('language');
        axios.get("https://admin.btcluk.com/api/banners/" + language)
            .then((res) => {
                const data = res.data.banners;
                const banners = data.map((banner, i) => {
                    return <Slide data={banner} key={i} />
                });
                const btns = data.map((banner, i) => {
                    return <span key={i} style={dash} className="dash" onClick={(e) => currentSlide(e, i)}></span>

                });
                setSlides(banners);
                setChangeBtns(btns);
                showSlides();
            });
    }, []);
    function currentSlide(e, n) {
        let slides = document.getElementsByClassName("slides");
        for (let i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        document.getElementsByClassName("slides")[n].style.display = "block";
        let dashes = document.getElementsByClassName("dash");
        for (let i = 0; i < dashes.length; i++) {
            dashes[i].style.backgroundColor = "#B8B8B8";
        }
        e.target.style.backgroundColor = '#060D8A';
    }

    let slideIndex = 0;
    function showSlides() {
        let slides = document.getElementsByClassName("slides");
        for (let i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        slideIndex++;
        if (slideIndex > slides.length) { slideIndex = 1 }
        if (slides[slideIndex - 1]) {
            slides[slideIndex - 1].style.display = "block";
            setTimeout(showSlides, 5000);
        } else {
            setTimeout(showSlides, 100);
        }


    }


    const container = {
        maxWidth: "auto",
        position: "relative"
    }

    const slideshowContainer = {
        position: "relative",
        margin: "auto",
        height: '100%'
    };


    const dash = {
        cursor: "pointer",
        height: "6px",
        width: "64px",
        borderRadius: '7px',
        margin: "0 12px",
        backgroundColor: "#B8B8B8",
        borderCradius: "50%",
        display: "inline-block",
        transition: " background-color 0.6s ease",
    };
    return (
        <div style={container}>
            <div id="slide-show-container" style={slideshowContainer}>
                {slides}
            </div>
            <div style={{ textAlign: 'center', position: 'absolute', bottom: '1rem', left: 0, right: 0, margin: "auto" }} id='Slider-btn-wrapper'>
                {changeBtns}
            </div>
        </div >
    );
}

export default Slider;