import { Link } from 'react-router-dom';
import bgImg from '../../images/teamProfileBg.png';
import playerImg from '../../images/teamProfileImg.png';
import teamLogo from '../../images/team-logo-big.png';
import mobileLogo from '../../images/mobile-icon.png';
import mailLogo from '../../images/mail-icon.png';
import facebookLogo from '../../images/facebook-icon.png';
import twitterLogo from '../../images/twitter-icon.png';
import youtubeLogo from '../../images/youtube-icon.png';
import instagramLogo from '../../images/instagram-icon.png';

function TeamProfileHeader(props) {
    const styles = {
        TeamDtsContainer: {
            maxWidth: 'var(--maxWidth)',
            height: '396px',
            backgroundImage: `url(${bgImg})`,
            margin: 'auto',
            display: 'flex',
            justifyContent: 'space-between'
        },
        imageWrapper: {
            width: '202px',
            height: '202px',
            background: 'white',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '3rem 2rem 0 1.5rem'
        },
        txt: {
            color: 'white',
            margin: '0 0 34px 0'
        },
        contactTxt: {
            marginRight: '2rem'
        },
        TeamPlayerContainer: {
            width: '1232px',
            display: 'grid',
            gridTemplateColumns: 'auto auto auto auto',
            rowGap: '2rem',
            columnGap: '6.5rem',
            justifyContent: 'center',
            margin: 'auto'
        },
        btn: {
            textDecoration: 'none',
            color: 'white',
            background: 'var(--blue)',
            padding: '7px 44px',
            borderRadius: '1rem',
            font: '600 14px var(--fontSSP)'
        }
    };

    return (
        <div>
            <div style={styles.TeamDtsContainer} id="TeamDetails">
                <div style={{ display: 'flex' }}>
                    <div style={styles.imageWrapper}>
                        <img src={"https://admin.btcluk.com/logo/" + props.data.imglogo} alt="" width="100%" height="100%" style={{ borderRadius: "50%" }} />
                    </div>
                    <div style={{ marginTop: '3rem' }}>
                        <h1 style={{ ...styles.txt, font: '800 2rem var(--fontInter)' }}>{props.data.name}</h1>
                        <h2 style={{ ...styles.txt, font: '500 1.5rem var(--fontInter)' }}>Play-Cricket Id : {props.data.pc_team_id}</h2>

                        <h2 style={{ ...styles.txt, font: '500 1.5rem var(--fontInter)' }}>Division : {props.data.division_name}</h2>
                        <a style={styles.btn} href={props.data.web_link} target='_blank'>website</a>

                        <div style={{ marginTop: '44px' }}>
                            <span style={styles.contactTxt}><img src={mobileLogo} alt="" /></span>
                            <span style={{ ...styles.txt, ...styles.contactTxt }}>{props.data.contactno}</span>
                            <span style={styles.contactTxt}><img src={mailLogo} alt="" /></span>
                            <span style={{ ...styles.txt, ...styles.contactTxt }}>{props.data.email}</span>
                        </div>
                        <hr style={{ marginTop: '34px', backgroundColor:'white',height: '1px',border: 'none' }}></hr>
                        {/* <div style={{ marginTop: '24px' }}>
                            <img style={{ marginRight: '24px' }} src={facebookLogo} alt="" />
                            <img style={{ marginRight: '24px' }} src={twitterLogo} alt="" />
                            <img style={{ marginRight: '24px' }} src={youtubeLogo} alt="" />
                            <img style={{ marginRight: '24px' }} src={instagramLogo} alt="" />
                        </div> */}
                    </div>
                </div>
                <div><img src={playerImg} alt="" /></div>
            </div>
        </div>
    );
}

export default TeamProfileHeader;