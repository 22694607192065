import Header from './Header';
import Footer from './Footer';
import Home from '../pages/home/Home';
import Leagues from '../pages/leagues/Leagues';
import PremierTeams from '../pages/leagues/PremierTeams';
import TeamProfile from '../pages/TeamProfile';
import PlayerProfile from '../pages/PlayerProfile';
import WhoWeAre from '../pages/whoweare/WhoWeAre';
import Btcl100Club from '../pages/Btcl100Club';
import Contact from '../pages/Contact';
import MobContact from '../Components/MobileComponents/MobContact';
import ScoreCard from '../pages/ScoreCard';
import News from '../pages/News';
import { Routes, Route } from "react-router-dom";
function Layout() {
    const width = window.innerWidth;
    return (
        <div>
            <Header />
            <div style={{ marginTop: '120px' }}>
                <Routes>
                    <Route path="*" element={<Home />} />
                    <Route path="leagues" element={<Leagues />} />
                    <Route path="leagues/:id" element={<PremierTeams />} />
                    <Route path="team-profile/:club_id/:team_id" element={<TeamProfile />} />
                    <Route path="player-profile/:id" element={<PlayerProfile />} />
                    <Route path="who-we-are/:id" element={<WhoWeAre />} />
                    <Route path="btcl-100-club" element={<Btcl100Club />} />
                    <Route path="view-score-card/:matchId" element={<ScoreCard />} />
                    {width > 600
                        ? <Route path="contact" element={<Contact />} />
                        : <Route path="contact" element={<MobContact />} />
                    }
                    <Route path="/news/:id" element={<News />} />
                </Routes>
            </div>
            <div style={{ backgroundColor: 'var(--blue)' }}>
                <Footer />
            </div>
        </div>

    );

}

export default Layout