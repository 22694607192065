import { Link } from 'react-router-dom';
import logo from '../images/logo.png';
import twitterLogo from '../images/twitter.png';
import youtubeLogo from '../images/youtube.png';
import facebookLogo from '../images/facebook.png';
import instagramLogo from '../images/instagram.png';
import locationLogo from '../images/location.png';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from '../translation';

function Footer() {
    const { t, i18n } = useTranslation();

    const [socialLink, setSocialLink] = useState([]);
    useEffect(() => {
        axios.get("https://admin.btcluk.com/api/socials").then((res) => {
            setSocialLink([res.data.socials.facebook, res.data.socials.twitter, res.data.socials.youtube, res.data.socials.instagram])
        });
    }, [])

    const [footer, setFooterContent] = useState([]);
    useEffect(() => {
        const language = localStorage.getItem('language');

        axios.get("https://admin.btcluk.com/api/footer/"+language).then((res) => {
            setFooterContent([res.data.footer.footer_text, res.data.footer.copy_text])
        });
    }, [])

        const handleScrollToTop = () => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        };

        


    return (
        <div id="Footer-container">
            <div>
                <div id='footer-logo-container'>
                    <img src={logo} width="128px" height="128px" />
                </div>
                <div className="Footer-txt" >
                    <p id="Footer-txt-content">
                    {footer[0]}
                    </p>
                </div>
                {/* <h2 className="Footer-txt">Newsletter</h2> */}
                {/* <div id="Footer-subscribe-btn-container">
                    <input type="email"  style={{ width: 190 }} name="" id="Footer-email" />
                    <button id="Footer-subscribe-btn" style={{ color: 'white',  }} type="submit">Subscribe</button>
                </div> */}
                <span id="copy-rights" className="Footer-txt">
                {footer[1]}
                </span>
            </div>
            <div>
                <h1 className='mob-footer-title'>BTCL</h1>
                <ul className="Footer-txt Footer-ul-cont">
                    <li><Link to="/" onClick={handleScrollToTop}>{t('home')}</Link></li>
                    <li><Link to="contact" onClick={handleScrollToTop}>{t('contact_us')}</Link></li>
                    <li><Link to="/who-we-are/0" onClick={handleScrollToTop}>{t('about_us')}</Link></li>
                    <li><Link to="/btcl-100-club" onClick={handleScrollToTop}>BTCL 100 Club Members</Link></li>
                </ul>
            </div>
            <div>
                <h1 className='mob-footer-title'>OUR DIVISIONS</h1>
                <ul className="Footer-txt Footer-ul-cont">
                    <li>Premier</li>
                    <li>Super</li>
                    <li>Champions</li>
                    <li>Legends</li>
                    <li>conference</li>
                    <li>Community</li>
                    <li>Challenge</li>
                    <li>classic</li>
                    <li>Platinum</li>
                </ul>
            </div>
            <div>
                <h1 className='mob-footer-title'>CONTACT US</h1>
                <ul className="Footer-txt Footer-ul-cont">
                    <li style={{ font: '300 .9rem var(--fontSSP)' }}> hello@btcluk.com</li>
                    <li id="Footer-social-logo-cont">
                        <a href={socialLink[0]} target='_blank'>
                            <span><img src={facebookLogo} /></span>
                        </a>
                        <a href={socialLink[1]} target='_blank'>
                            <span><img src={twitterLogo} /></span>
                        </a>
                        <a href={socialLink[2]} target='_blank'>
                            <span><img src={youtubeLogo} /></span>
                        </a>
                        <a href={socialLink[3]} target='_blank'>
                            <span><img src={instagramLogo} /></span>
                        </a>

                    </li>
                    <li><Link>Terms & Conditions</Link></li>
                    <li><Link>Privacy Policy</Link></li>
                    <li> <a target='blank' href="https://www.google.com/maps/uv?pb=!1s0x48760a6aa0a2d8ff%3A0xd32e17d4b62600d!3m1!7e115!4s%2Fmaps%2Fplace%2FBritish%2BTamils%2BCricket%2BLeague%2B(BTCL)%2B14%2BElm%2BRoad%2B%2C%2BChessington%2BSurrey%2BKT9%2B1AW%2F%4051.36765%2C-0.3059016%2C3a%2C75y%2C111.51h%2C90t%2Fdata%3D*213m4*211e1*213m2*211ssKeIabtNRn5Gl9dMu9rO0w*212e0*214m2*213m1*211s0x48760a6aa0a2d8ff%3A0xd32e17d4b62600d%3Fsa%3DX!5sBritish%20Tamils%20Cricket%20League%20(BTCL)%2014%20Elm%20Road%20%2C%20Chessington%20Surrey%20KT9%201AW%20-%20Google%20Search!15sCgIgAQ&imagekey=!1e2!2ssKeIabtNRn5Gl9dMu9rO0w&hl=en&sa=X&ved=2ahUKEwjE1tTZyrr_AhXhXGwGHYNFDLoQpx96BAg_EAU"><img src={locationLogo} /></a></li>
                    <li className="Footer-txt">
                        British Tamils Cricket League <br />
                        (BTCL) <br />
                        14 Elm Road, <br />
                        Chessington, Surrey <br />
                        KT9 1AW
                    </li>
                </ul>
                <span id="copy-rights-2" className="Footer-txt">
                    © 2023 British Tamils Cricket League. All Rights Reserved
                </span>
            </div>
        </div>
    );
}

export default Footer;