import { Link } from 'react-router-dom';
import playerImg from '../../images/player.png';
import flight from '../../images/flight.png';
import home from '../../images/home.png';

function PlayerCard(props) {
    const styles = {
        card: {
            width: '212px',
            height: '339px',
            background: 'var(--blue)',
            borderRadius: '12px',
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            padding: '0.5rem 0',
            boxSizing: 'border-box',
            whiteSpace: 'nowrap',
            flexShrink: 0
        },
        imageWrapper: {
            width: '102px',
            height: '102px',
            background: 'white',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden'
        },
        playerDtsWrapper: {
            font: '500 16px var(--fontInter)',
            margin: 0,
            color: 'white',
            padding: '0 1rem',
            display: 'flex',
            flexFlow: "column",
            rowGap: '.5rem'
        },
        txt: {
            margin: 0,
            font: '400 12px var(--fontInter)',
        },
        btn: {
            textDecoration: 'none',
            font: '400 16px var(--fontSSP)',
            color: 'var(--blue)',
            background: 'white',
            padding: '.4rem 3.2rem',
            borderRadius: '16px',
            marginTop: '24px'
        }
    };

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
      
    return (
        <div style={styles.card}>
            { props.data.player_type == 'Overseas' &&<img src={flight} style={{marginLeft:'auto',display:'inline', marginRight:'10px'}}  width='20px' alt="Overseas" />}{ props.data.player_type == 'Home' &&<img src={home} style={{marginLeft:'auto',display:'inline', marginRight:'10px'}}  width='20px' alt="Overseas" />}
            <div style={styles.imageWrapper}>
                <img src={props.data.Photo ? "https://admin.btcluk.com/players/" + props.data.Photo : playerImg} alt="" width="100%" height="100%" />
            </div>
            <p style={{ font: '800 16px var(--fontInter)', margin: '16px 0 24px 0', textAlign: 'center', overflow: 'hidden', color: 'white', width: '100%' }}>{props.data.Forename}</p>
            <div style={styles.playerDtsWrapper}>
                <p style={styles.txt}>Age Group : {props.data.AgeGroup}</p>
                <p style={styles.txt}>Player ID :{props.data.PlayerID}</p>
                <p style={styles.txt}>Batting : {props.data.BatStyle}</p>
                <p style={styles.txt}>Bowling : {props.data.BowlStyle}</p>
            </div>
            <Link  onClick={handleScrollToTop} to={"/player-profile/" + props.data.PlayerID} state={props.teamId} style={styles.btn}>Profile</Link>
        </div>
    );
}

export default PlayerCard;