import axios from "axios";
import { useEffect, useState } from "react";
function AdsSection() {
    const [url, setUrl] = useState('');
    const [title, setTitle] = useState('');
    const [subTxt, setSubTxt] = useState('');
    const [link, setLink] = useState('');

    useEffect(() => {
        const language = localStorage.getItem('language');
        axios.get("https://admin.btcluk.com/api/ads/" + language).then((res) => {
            setUrl("https://admin.btcluk.com/files/" + res.data.ads.image)
            setTitle(res.data.ads.title);
            setSubTxt(res.data.ads.sub_text);
            setLink(res.data.ads.link)

        });
    }, []);
    return (
        <div id="ads" >
                                

            <img id="ad-image" src={url} style={{ margin: 'auto' }} />
            <a href={link} target='_blank'>  <p style={{ font: '600 1rem var(--fontSSP)' }}>{title}</p></a>
            <p style={{ font: '400 1rem var(--fontSSP)' }}>{subTxt}</p>
            
        </div>
    );
}

export default AdsSection;