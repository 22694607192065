import PageTitle from "./../Components/UIcomponents/PageTitle";
import MemberCard from "../Components/UIcomponents/MemberCard";
import bg from './../images/btcl-100-club.png';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from "react";

function Btcl100Club() {

    const [one, setOne] = useState('');
    const [two, setTwo] = useState('');
    const [three, setThree] = useState('');
    const [four, setFour] = useState('');


    useEffect(() => {
        const language = localStorage.getItem('language');
        axios.get("https://admin.btcluk.com/api/hundredClub/" + language)
            .then((res) => {
              const  hun = res.data.hundredClub;
              setOne(hun.text_one);
              setTwo(hun.text_two);
              setThree(hun.text_three);
              setFour(hun.text_four);


            });
    }, []);

    const styles = {
        tab: {
            maxWidth: 'var(--maxWidth)',
            margin: '2rem 1.5rem',
            boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
            borderRadius: '12px',
            padding: '2rem'
        },
        tabBtnWrapper: {
            height: '48px',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '2rem',
            padding: '0 6rem'
        },
        Btn: {
            background: 'white',
            border: 'none',
            font: '800 16px var(--fontInter)',
            cursor: 'pointer'
        },
        visionPointsWrapper: {
            display: 'flex',
        },
        visionPointsNo: {
            marginRight: '2rem'
        },
        visionPointsTxt: {

        }
    };

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };

    return (
        <div>
            <PageTitle name='BTCL 100 CLUB' />
            <div style={{ margin: '2rem auto', maxWidth: 'var(--maxWidth)' }}>
                <div style={styles.tab}>
                   
                    <p style={{ font: '400 1rem var(--fontSSP)' }} dangerouslySetInnerHTML={{ __html: one }} ></p> 

            
                </div>
                <div style={styles.tab}>
                <p style={{ font: '400 1rem var(--fontSSP)' }} dangerouslySetInnerHTML={{ __html: two }} ></p> 

                </div>
                <div style={styles.tab}>
                <p style={{ font: '400 1rem var(--fontSSP)' }} dangerouslySetInnerHTML={{ __html: three }} ></p> 

                </div>
                <div style={{
                    background: `url(${bg}) no-repeat`, height: '290px', display: 'flex',
                    flexFlow: 'column', justifyContent: "center", alignItems: 'center',
                    maxWidth: 'var(--maxWidth)', margin: '2rem 1.5rem', borderRadius: "1rem",
                    backgroundSize: 'cover'
                }}>
                    <p id="btcl-100-club-txt" style={{ color: 'white', textAlign: 'center' }}  dangerouslySetInnerHTML={{ __html: four }}>
                       
                    </p>
                    <Link to="/contact" onClick={handleScrollToTop} style={{
                        backgroundColor: 'var(--yellow)', color: 'white', textDecoration: 'none', font: '600 14px var(--fontSSP)',
                        padding: '.5rem 3rem', borderRadius: '1rem', width: 'fit-content'
                    }}>Contact Us</Link>
                </div>
                {/* <h1>BTCL 100 CLUB MEMBERS</h1>
                <div>
                    <MemberCard />
                </div> */}
            </div>
        </div>
    );
}

export default Btcl100Club;