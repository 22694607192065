import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
function PlalyerStat() {
    const playerId = useParams();
    const [battingStat, setBattingStat] = useState([]);
    const [bowlingStat, setBowlingStat] = useState('');

    useEffect(() => {
        axios.get("https://admin.btcluk.com/api/playerStats/" + playerId.id).then((res) => {
            const batting = res.data.batstats;
            const bowling = res.data.bowstats;
            const battingStatList = batting.map((item, i) => {
                return (
                    < tr key={i} style={{ height: '40px' }}>
                        <td>{item.year}</td>
                        <td>{item.matchs}</td>
                        <td>{item.runs}</td>
                        <td>{item['50s']}</td>
                        <td>{item['100s']}</td>
                        <td>{item.BS}</td>
                        <td>{item.AVG}</td>
                    </tr >
                );
            });
            setBattingStat(battingStatList);
            const bowlingStatList = bowling.map((item, i) => {
                return (
                    < tr key={i} style={{ height: '40px' }}>
                        <td>{item.year}</td>
                        <td>{item.matchs}</td>
                        <td>{item.wkts}</td>
                        <td>{item.ER}</td>
                        <td>{item.BB}</td>
                        <td>{item.AVG}</td>
                    </tr >
                );
            });
            setBowlingStat(bowlingStatList);
        });
    }, [playerId.id]);

    const statTable = {
        width: '100%',
        borderCollapse: 'collapse',
        marginTop: '1rem',
        textAlign: 'center'
    };

    return (
        <>
            <h1 style={{ font: '700 24px var(--fontSSP)', color: 'var(--blue)', marginTop: '2rem' }}>BATTING STATISTICS</h1>
            <div style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)' }}>
                <table id="Points-table" style={statTable}>
                    <thead>
                        <tr style={{ background: 'var(--blue)', color: 'white', fontWeight: 600, height: '40px' }}>
                            <th>Year</th>
                            <th>Matches</th>
                            <th>Runs</th>
                            <th>50s</th>
                            <th>100s</th>
                            <th>BS</th>
                            <th>Avg</th>
                        </tr>
                    </thead>
                    <tbody>
                        {battingStat}
                    </tbody>
                </table>
            </div>
            <h1 style={{ font: '700 24px var(--fontSSP)', color: 'var(--blue)', marginTop: '2rem' }}>BOWLING STATISTICS</h1>
            <div style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)' }}>
                <table id="Points-table" style={statTable}>
                    <thead>
                        <tr style={{ background: 'var(--blue)', color: 'white', fontWeight: 600, height: '40px' }}>
                            <th>Year</th>
                            <th>Matches</th>
                            <th>Wickets</th>
                            <th>ER</th>
                            <th>BB</th>
                            <th>Avg</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bowlingStat}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default PlalyerStat;