import { Link } from 'react-router-dom';
import teamLogo from '../../images/team-logo.png';
import teamLogo2 from '../../images/team-logo2.png';
import calandarIcon from '../../images/calendar-icon.png';
import locationIcon from '../../images/location-icon.png';
function MobResultCard({ data }) {
    const styles = {
        card: {
            width: '100%',
            height: '150px',
            background: 'var(--blue)',
            borderRadius: '12px',
            marginBottom: '1rem',
            padding: '.5rem',
            boxSizing: 'border-box',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: "space-around"
        },
        imageWrapper: {
            width: '65px',
            height: '65px',
            background: 'white',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexShrink: 0
        },
        TeamsWrapper: {
            display: 'flex',
            justifyContent: 'center',
            columnGap: '1rem',
            color: 'white',
            marginTop: '.5rem'
        },
        btn: {
            textDecoration: 'none',
            font: '400 16px var(--fontSSP)',
            color: 'var(--blue)',
            background: 'white',
            padding: '.4rem 3.2rem',
            borderRadius: '16px'
        },
        title: {
            background: 'var(--yellow)',
            color: ' var(--blue)',
            width: 'fit-content',
            margin: 0,
            padding: '.2rem 1rem',
            borderRadius: '1rem',
            font: '600 .7rem var(--fontSSP)'
        }
    };
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
    return (
        <div style={styles.card}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p style={styles.title}>{data.msg}</p>
                <p style={{ color: 'white', font: '600 .7rem var(--fontSSP)', margin: 0 }}>{data.math_date}</p>
            </div>
            <div style={styles.TeamsWrapper}>
                <div style={{ display: 'flex', columnGap: '.5rem', alignItems: 'center' }}>
                    <div style={styles.imageWrapper}>
                        <img src={data.home_team_logo ? "https://admin.btcluk.com/logo/" + data.home_team_logo : teamLogo} alt="" width="100%" height="100%" />
                    </div>
                    <div>
                        <p style={{ font: '600 .7rem var(--fontSSP)', margin: 0 }}>{data.left_club}-{data.left_team}</p>
                        <span style={{ font: '600 .7rem var(--fontSSP)' }}>                        <span>{data.left_run !== '' ? data.left_run + ' / ' : ''}{data.left_w}</span>
</span>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ font: 'italic 600 1rem var(--fontSSP)' }}>VS</span>
                </div>
                <div style={{ display: 'flex', columnGap: '.5rem', alignItems: 'center' }}>
                    <div style={styles.imageWrapper}>
                        <img src={data.away_team_logo ? "https://admin.btcluk.com/logo/" + data.away_team_logo : teamLogo2} alt="" width="100%" height="100%" />
                    </div>
                    <div>
                        <p style={{ font: '600 .7rem var(--fontSSP)', margin: 0 }}>{data.right_club}-{data.right_team}</p>
                        <span style={{ font: '600 .7rem var(--fontSSP)' }}> <span>{data.right_run !== '' ? data.left_run + ' / ' : ''}{data.right_w}</span></span>
                    </div>
                </div>

            </div>
            {/* <div>
                <div style={{ display: 'flex' }}>
                    <span>
                        <img src={calandarIcon} alt="" />
                    </span>
                    <span>
                        data.match_date
                    </span>
                </div>
                <div style={{ display: 'flex' }}>
                    <span>
                        <img src={locationIcon} alt="" />
                    </span>
                    <span style={{ width: '242px', display: 'inline-block' }}>
                        data.ground_name
                    </span>
                </div>
            </div> */}
            <div style={{ textAlign: 'center' }}>
                {data.msg != "Abandoned" &&
                    <Link to={"/view-score-card/" + data.id} style={{
                        backgroundColor: '#0a7d0a', font: ' 600 .5rem var(--fontSSP)',
                        textDecoration: 'none', padding: '1px 10px', borderRadius: '15px'
                    }} onClick={handleScrollToTop} > <span style={{ color: 'white' }}>view score card</span></Link>
                }
            </div>
        </div >
    );
}

export default MobResultCard;