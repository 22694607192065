import { useParams } from 'react-router-dom';
import PageTitle from '../Components/UIcomponents/PageTitle';
import { useEffect, useState } from 'react';
import { BsFillShareFill } from 'react-icons/bs';
import axios from 'axios';
export default function News(props) {
    window.scrollTo(0, 0)

    const windowWidth = window.innerWidth > 600 ? true : false;
    const [title, setTitle] = useState();
    const [content, setContent] = useState();
    const [image, setImage] = useState();
    const [id, setId] = useState();
    const [date, setDate] = useState();

    const urls = useParams();

    const handleShare = () => {
        const url = 'https://demo.btcluk.com/news/' + id;
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank');
    };

    useEffect(() => {
        axios.get("https://admin.btcluk.com/api/newsDetails/" + urls.id).then((res) => {
            
            setImage(res.data.news.image);
            setTitle(res.data.news.title);
            setContent(res.data.news.content);
            setId(res.data.news.id);
            setDate(res.data.news.publish_date);

        });
    }, []);
    return (
        <div style={{ maxWidth: 'var(--maxWidth)', margin: 'auto', minHeight: '100vh', padding: '0 1rem' }}>
            <PageTitle name="News" />
            {windowWidth
                ? <div>
                    <img src={"https://admin.btcluk.com/files/" + image} alt="" style={{ float: 'right', width: '50%', marginLeft: '1rem', borderRadius: '1rem' }} />
                    <div>
                        <h1 style={{ font: '700 2rem var(--fontSSP)', color: '#060D8A' }}>{title}</h1>
                        <div style={{ display: "flex", columnGap: '1rem' }}>
                            <span style={{ color: "var(--yellow)", font: '400 1rem var(--fontSSP)' }}>{date}</span>
                            <a href="#" onClick={handleShare} style={{ display: 'flex', columnGap: '1rem', color: "white", font: '400 1rem var(--fontSSP)', backgroundColor: "var(--yellow)", padding: '2px 10px', borderRadius: "15px", alignItems: 'center', textDecoration: 'none' }}> <BsFillShareFill /><span>share</span></a>
                        </div>
                    </div>
                    <p style={{ font: '400 1rem var(--fontSSP)', lineHeight: 1.5 }}>{content}</p>
                </div>
                : <div>
                    <div style={{ marginBottom: '1rem' }}>
                        <h1 style={{ font: '700 2rem var(--fontSSP)', color: '#060D8A' }}>{title}</h1>
                        <div style={{ display: "flex", columnGap: '1rem', justifyContent: "space-around" }}>
                            <span style={{ color: "var(--yellow)", font: '400 1rem var(--fontSSP)' }}>12 june 2023</span>
                            <span onClick={handleShare} style={{ display: 'flex', columnGap: '1rem', color: "white", font: '400 1rem var(--fontSSP)', backgroundColor: "var(--yellow)", padding: '2px 10px', borderRadius: "15px", alignItems: 'center' }}> <BsFillShareFill /><span>share</span></span>
                        </div>
                    </div>
                    <img src={"https://admin.btcluk.com/files/" + image} alt="" style={{ width: '100%', borderRadius: '1rem' }} />

                    <p style={{ font: '400 1rem var(--fontSSP)', lineHeight: 1.5 }}>{content}</p>
                </div>
            }

        </div>
    );
}