import PageTitle from "../../Components/UIcomponents/PageTitle";
import AboutBtcl from "./components/AboutBtcl";
import Constitution from "./components/Constitution";
import Discipline from "./components/Discipline";
import Committee from "./components/Committee";
import PlayingConditions from "./components/PlayingConditions";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { useTranslation } from '../../translation';

function WhoWeAre() {
    const { t, i18n } = useTranslation();

    const [about, setAbout] = useState('');
    const [constitution, setConstitution] = useState('');
    const [discipline, setDiscipline] = useState('');
    const [playingCondition, setPlayingCondition] = useState('');
    const urls = useParams()
    useEffect(() => {
        const language = localStorage.getItem('language');
        if (urls.id == 1) {
            let i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tabcontent");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tablinks");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById('PlayingConditions').style.display = "block";
            document.getElementById('playigConditionLink').className += " active";
        }
        axios.get("https://admin.btcluk.com/api/about/" + language).then((res) => {
            const about = res.data.about;
            setAbout(about.about_btcl);
            setConstitution(about.constitution);
            setDiscipline(about.discipline)
            setPlayingCondition(about.playing_condition);
        });
    }, []);

    const mobileScreen = 600;
    const desktopScreen = 1200;

    const styles = {
        tab: {
            maxWidth: 'var(--maxWidth)',
            margin: '0 1.5rem',
            boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
            borderRadius: '12px'
        },
        tabBtnWrapper: {
            height: '48px',
            display: 'grid',
            justifyContent: 'space-around',
            marginBottom: '2rem',
            rowGap: '.5rem'
        },
        Btn: {
            background: 'white',
            border: 'none',
            font: '800 16px var(--fontInter)',
            cursor: 'pointer'
        },
        Btnpc: {
            background: 'white',
            border: 'none',
            font: '800 16px var(--fontInter)',
            cursor: 'pointer'
        },
        visionPointsWrapper: {
            display: 'flex',
        },
        visionPointsNo: {
            marginRight: '2rem'
        }
    };

    if (window.innerWidth <= mobileScreen) {
        styles.Btn.font = '800 16px var(--fontInter)';
        styles.Btn.marginBottom = '5px';

        styles.Btn.width = '100%';
        styles.Btnpc.display = 'none';

        styles.tabBtnWrapper.marginBottom = '30px';
    }
   



    

    function openTab(e, id) {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(id).style.display = "block";
        e.currentTarget.className += " active";
    }
    return (
        <div>
            <PageTitle name='WHO WE ARE ?' />
            <div style={{ margin: '2rem auto', maxWidth: 'var(--maxWidth)' }}>
                <div id="WhoweareTab" style={styles.tab}>
                    <div id="who-area-btn-wrapper" style={styles.tabBtnWrapper}>
                        <button className="tablinks active" style={styles.Btn} onClick={(e) => openTab(e, 'About-Btcl')}>{t('about_btcl')}</button>
                        <button className="tablinks" style={styles.Btn} onClick={(e) => openTab(e, 'Constitution')}>{t('constitution')}</button>
                        <button id="playigConditionLink" className="tablinks" style={styles.Btnpc} onClick={(e) => openTab(e, 'PlayingConditions')}>{t('playing_condition')}</button>
                        <button className="tablinks" style={styles.Btn} onClick={(e) => openTab(e, 'Discipline')}>{t('disipline')}</button>
                        <button className="tablinks" style={styles.Btn} onClick={(e) => openTab(e, 'Committee')}>{t('committee')}</button>
                    </div>

                    <AboutBtcl data={about} />
                    <Constitution data={constitution} />
                    <Discipline data={discipline} />
                    <PlayingConditions data={playingCondition} />
                    <Committee />
                </div>
            </div>
        </div>
    );
}

export default WhoWeAre;