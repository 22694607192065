import Hero from './components/Hero';
import Slider from './components/Slider';
import AdsSection from './components/AdsSection';
import PointsTable from './components/PointsTable';
import News from './components/News';
import FeaturedVideos from './components/FeaturedVideos';
import Summery from './components/Summery';
import BtclPartner from './components/BtclPartners';
function Home() {
    return (
        <div style={{ maxWidth: 'var(--maxWidth)', margin: 'auto' }}>
            <div id="main-slider-container" style={{ maxWidth: 'var(--maxWidth)' }}>
                <Hero />
                {/* <Slider /> */}
            </div>
            <div style={{ maxWidth: 'var(--maxWidth)', margin: '0 1.5rem' }}>
                {/* <Slider /> */}
                <div id="ads-points-container" style={{ display: 'flex', margin: '2rem 0 60px 0', justifyContent: 'space-between', columnGap: '2rem' }}>
                    <AdsSection />
                    <PointsTable />
                </div>
                <News />
                <FeaturedVideos />
                <Summery />
                <BtclPartner />
            </div>
        </div>
    );
}

export default Home;