import featured from '../../../images/featured.png';
import share from '../../../images/share-icon-2.png';
function FeaturedVidCard({ data, size }) {
    const styles = {
        card: {
            width: 'fit-content',
            backgroundOrigin: 'border-box',
            position: 'relative',
            borderRadius: '12px',
            fontFamily: 'var(--fontSSP)',
        },
        title: {
            fontSize: '16px',
            fontWeight: 600,
            margin: 0,
            textAlign: 'center',
        },
        content: {
            top: 0,
            backgroundColor: "black",
            color: 'white',
            padding: '10px',
            borderRadius: '10px',
            marginTop: '-15px'
        },
        textWrapper: {
            backgroundColor: "var(--blue)",
            color: "var(--yellow)",
            borderRadius: '12px 12px 0 0',
            padding: '.5rem 0'
        },
        shareBtn: {
            marginRight: '1rem'
        }
    };
    return (
        <div style={styles.card}>
            <div style={styles.textWrapper}>
                <h2 style={styles.title}>{data.title}</h2>
            </div>
            <video width={size} controls>
                <source src={"https://admin.btcluk.com/files/" + data.vedio} type="video/mp4" />
            </video>
            <p style={styles.content}>{data.content.slice(0, 40)}{data.content.length > 50 ? '...' : ''}</p>

        </div>
    );
}

export default FeaturedVidCard;