import NewsCard from "./NewsCard";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from '../../../translation';

function News() {
    const { t, i18n } = useTranslation();

    const [news, setNews] = useState('')
    useEffect(() => {
        const language = localStorage.getItem('language');
        axios.get("https://admin.btcluk.com/api/news/" + language)
            .then((res) => {
                const data = res.data.news;
                const newsList = data.map((news, i) => {
                    return <NewsCard data={news} key={i} />
                });
                setNews(newsList);

            });
    }, []);
    let sliderContainer = 0;
    let slider = 0;
    let SliderWidth = 0;
    let sliderContainerWidth = 0;

    function initSlider() {
        sliderContainer = document.getElementById('sliderContainer');
        slider = document.getElementById('sliderWrapper');
        SliderWidth = parseInt(getComputedStyle(slider).width);
        sliderContainerWidth = parseInt(getComputedStyle(sliderContainer).width);
    }

    function next(val) {
        const left = parseInt(getComputedStyle(slider).left);
        if (-left < (SliderWidth - 827)) {
            slider.style.left = -(val - left) + 'px';
        }

    }
    function prev(val) {
        const left = parseInt(getComputedStyle(slider).left);
        if (left < 0) {
            slider.style.left = val + left + 'px';
        } else {
            slider.style.left = '0px';
        }
    }


    return (
        <div >
            <h2 style={{ font: '700 24px var(--fontSSP)', color: 'var(--blue)' }}>{t('news')}</h2>
            <div id="sliderContainer" style={{ overFlow: 'hidden', position: 'relative', width: '100%', height: '500px', overflow: 'hidden' }}>
                <div id="sliderWrapper" style={{
                    display: 'flex', columnGap: '2rem', position: 'absolute', left: 0,
                    transition: 'left 0.5s linear 0s'
                }} onLoad={initSlider}>
                    {news}
                </div>
                <div style={{
                    position: "absolute", color: 'white', backgroundColor: "#2b34e1", width: '30px', height: '30px', borderRadius: '50%',
                    display: 'flex', justifyContent: 'center', alignItems: 'center', top: 0, bottom: 25, margin: 'auto', fontSize: '2rem',
                    cursor: 'pointer'
                }} onClick={e => { prev(327) }}>
                    <span style={{ fontSize: '10px' }}>&lt;</span>
                </div>
                <div style={{
                    position: "absolute", color: 'white', backgroundColor: "#2b34e1", width: '30px', height: '30px', borderRadius: '50%',
                    display: 'flex', justifyContent: 'center', alignItems: 'center', top: 0, bottom: 25, right: 0, margin: 'auto', fontSize: '2rem',
                    cursor: 'pointer'
                }} onClick={e => { next(327) }}>
                    <span style={{ fontSize: '10px' }}>&gt;</span>
                </div>
            </div>
        </div>
    );
}

export default News;