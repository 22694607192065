import React from 'react';
import mapImg from './../images/map.png';
import mailImg from './../images/contact-mail.png';
import phoneImg from './../images/contact-phone.png';
import locationImg from './../images/contact-location.png';
import axios from 'axios';
import bgImg from '../images/teamProfileBg.png';
import { withTranslation } from 'react-i18next';

class Contact extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            success: '',
            email: '',
            phoneNo: '',
            yourMsg: '',
            formErrors: { name: '', email: '', phoneNo: '', yourMsg: '' },
            nameValid: false,
            emailValid: false,
            phoneNoValid: false,
            yourMsgValid: false,
            formValid: false,
            texts: { contact_heading: '', contact_email_sub_heading: '', address_sub_heading
            : '', contact_call_sub_heading: '' },

        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateField = this.validateField.bind(this);
        this.validateForm = this.validateForm.bind(this);

    }
    

    handleChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value }, () => { this.validateField(name, value) });
    }

    

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let nameValid = this.state.nameValid;
        let emailValid = this.state.emailValid;
        let phoneNoValid = this.state.phoneNoValid;
        let yourMsgValid = this.state.yourMsgValid;

        switch (fieldName) {
            case 'name':
                nameValid = value.length >= 1;
                fieldValidationErrors.name = nameValid ? '' : 'This field is required';
                break;
            case 'email':
                emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                fieldValidationErrors.email = emailValid ? '' : ' This Email is invalid';
                break;
            case 'phoneNo':
                phoneNoValid = value.length >= 1;
                fieldValidationErrors.company = phoneNoValid ? '' : 'This field is required';
                break;
            case 'yourMsg':
                yourMsgValid = value.length >= 1;
                fieldValidationErrors.Idea = yourMsgValid ? '' : 'This field is required';
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            nameValid: nameValid,
            emailValid: emailValid,
            phoneNoValid: phoneNoValid,
            yourMsgValid: yourMsgValid,
        }, this.validateForm);
    }
    validateForm() {
        this.setState({ formValid: this.state.nameValid && this.state.emailValid && this.state.phoneNoValid && this.state.yourMsgValid });
    }
    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            success : 'Message Sending...' 
        })
        const data = {
            name: this.state.name,
            admin_email: this.state.email,
            mobile: this.state.phoneNo,
            msg: this.state.yourMsg
        }
        axios.post("https://admin.btcluk.com/api/mail", data).then((res) => {
            this.setState({
                success : 'Sent !',

                success : res.data 
            })

        });
    }
    styles = {
        contactWrapper: {
            width: '1232px',
            boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
            borderRadius: ' 12px',
            display: 'flex',
            justifyContent: 'space-between',
            margin: '0 1.5rem'
        },
        input: {
            width: '544px',
            height: '44px',
            border: 'none',
            boxShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '12px',
            paddingLeft:'10px',
            marginTop:'5px'
            

        },
        label: {

            font: '400 1rem var(--fontSSP)',

        },
        textarea: {
            width: '544px',
            height: '167px',
            resize: 'none',
            border: 'none',
            boxShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '12px',
            paddingLeft:'10px',
            paddingTop:'10px',
            marginTop:'10px'



        },
        star: {
            color: '#CC2229'
        },
        mainstyle:{
            width: '1280px', margin: '0 auto', marginBottom: '80px',backgroundImage:bgImg
        }
    };

    componentDidMount() {
        const language = localStorage.getItem('language');
        axios.get("https://admin.btcluk.com/api/CommonText/" + language)
            .then((res) => {
                console.log(res.data.CommonText)
                this.setState({ texts : res.data.CommonText });
            })
            .catch((error) => {
                console.log(error);
            });
    }
    

    render() {
        const { t } = this.props;
        const { texts } = this.state;


            

        return (
            <div style={this.styles.mainstyle}>
                <div style={this.styles.contactWrapper}>
                    <div style={{
                        background: 'var(--blue)', color: 'white', width: '500px', height: '601px',
                        padding: '2rem 3rem', borderRadius: '12px', boxSizing: 'border-box'
                    }}>
                        <p style={{ font: '700 1rem var(--fontSSP)', textAlign: 'center', margin: '0 0 3rem 0' , font: '400 1rem var(--fontSSP)'}} dangerouslySetInnerHTML={{ __html: texts.contact_heading }} ></p>

                        <div style={{ display: 'flex', marginBottom: '2rem' }}>
                            <div style={{ marginRight: '2rem' }}><img src={mailImg} alt="" /></div>
                            <div>
                                <p style={{ font: '400 1rem var(--fontSSP)', marginTop: '-20px' }} dangerouslySetInnerHTML={{ __html: texts.contact_email_sub_heading }} ></p> 

                                <p style={{ font: '300 .9rem var(--fontSSP)',marginTop: '-15px' }}> hello@btcluk.com</p>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginBottom: '2rem' }}>
                            <div style={{ marginRight: '2rem'}}><img src={phoneImg} alt="" /></div>

                            <div>
                            <p style={{ font: '400 1rem var(--fontSSP)', marginTop: '-20px' }} dangerouslySetInnerHTML={{ __html: texts.contact_call_sub_heading }} ></p> 
                                <p style={{ font: '300 .9rem var(--fontSSP)', marginTop: '-15px' }}>020 8150 5667</p>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginBottom: '2rem' }}>
                            <div style={{ marginRight: '2rem' }}><img src={locationImg} alt="" /></div>

                            <div>
                            <p style={{ font: '400 1rem var(--fontSSP)', marginTop: '-20px' }} dangerouslySetInnerHTML={{ __html: texts.address_sub_heading }} ></p> 
                                <p style={{ font: '300 .9rem var(--fontSSP)', marginTop: '-15px'}}>British Tamils Cricket League (BTCL)</p>
                                <p style={{ font: '300 .9rem var(--fontSSP)', marginTop: '-15px' }}>14 Elm Road , Chessington</p>
                                <p style={{ font: '300 .9rem var(--fontSSP)', marginTop: '-15px' }}>Surrey KT9 1AW</p>
                            </div>
                        </div>
                         <a target='blank' href="https://www.google.com/maps/uv?pb=!1s0x48760a6aa0a2d8ff%3A0xd32e17d4b62600d!3m1!7e115!4s%2Fmaps%2Fplace%2FBritish%2BTamils%2BCricket%2BLeague%2B(BTCL)%2B14%2BElm%2BRoad%2B%2C%2BChessington%2BSurrey%2BKT9%2B1AW%2F%4051.36765%2C-0.3059016%2C3a%2C75y%2C111.51h%2C90t%2Fdata%3D*213m4*211e1*213m2*211ssKeIabtNRn5Gl9dMu9rO0w*212e0*214m2*213m1*211s0x48760a6aa0a2d8ff%3A0xd32e17d4b62600d%3Fsa%3DX!5sBritish%20Tamils%20Cricket%20League%20(BTCL)%2014%20Elm%20Road%20%2C%20Chessington%20Surrey%20KT9%201AW%20-%20Google%20Search!15sCgIgAQ&imagekey=!1e2!2ssKeIabtNRn5Gl9dMu9rO0w&hl=en&sa=X&ved=2ahUKEwjE1tTZyrr_AhXhXGwGHYNFDLoQpx96BAg_EAU"><img src={mapImg} alt="" style={{ marginLeft: '3rem' ,border:"solid 3px var(--yellow)" , borderRadius:"15px"  }} /></a>
                    </div>
                    <div style={{ padding: "2rem" }}>
                        <form onSubmit={this.handleSubmit}>
                            <div>
                                <label style={this.styles.label}>{t('full_name')}<sup style={this.styles.star}>*</sup></label><br />
                                <input style={this.styles.input} type="text" id="name" name="name" value={this.state.name} onChange={this.handleChange} />
                            </div>
                            <p  style={{ color:'red',fontSize:'10px' }} >{this.state.formErrors.name}</p>
                            <div>
                                <label style={this.styles.label}>{t('phone')}<sup style={this.styles.star}>*</sup></label><br />
                                <input style={this.styles.input} type="text" id="phoneNo" name="phoneNo" value={this.state.company} onChange={this.handleChange} />
                            </div>
                            <p  style={{ color:'red',fontSize:'10px' }} >{this.state.formErrors.company}</p>
                            <div>
                                <label style={this.styles.label}>{t('email')}<sup style={this.styles.star}>*</sup></label><br />
                                <input style={this.styles.input} type="email" name="email" id="email" value={this.state.email} onChange={this.handleChange} />
                            </div>
                            <p  style={{ color:'red',fontSize:'10px' }} > { this.state.formErrors.email}</p>
                            <div>
                                <label style={this.styles.label}>{t('message')}<sup style={this.styles.star}>*</sup></label><br />
                                <textarea style={this.styles.textarea} id="des-your-idea" name="yourMsg" value={this.state.yourMsg} onChange={this.handleChange} ></textarea>
                            </div>
                            <p  style={{ color:'red',fontSize:'10px' }} >{this.state.formErrors.Idea}</p>
                            <div style={{ textAlign: 'center' }}>
                            <p  style={{ color:'green',fontSize:'20px',font: '400 1rem var(--fontSSP)' }} > { this.state.success}</p>

                                <div><input style={{
                                    backgroundColor: "var(--yellow)", color: 'white', border: 'none', padding: '.5rem 2rem', borderRadius: "1rem", font: '400 1rem var(--fontSSP)', cursor: 'pointer'
                                }} disabled={!this.state.formValid} type="submit" value={t('submit')} /></div>
                            </div>
                        </form>
                    </div>

                </div>
            </div >
        );
    }
}
export default withTranslation()(Contact);
