import PlayerProfileHeader from "../Components/Player/PlayerProfileHeader";
import MobPlayerProfileHeader from "../Components/MobileComponents/MobPlayerProfileHeader";
import PlayerCard from "../Components/Player/PlayerCard";
import PlalyerStat from "../Components/Player/PlayerStat";
import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import axios from "axios";

function PlayerProfile(props) {
    const [otherPlayers, setOtherPlayers] = useState('');
    const [playerProfile, setPlayerProfile] = useState('');
    const location = useLocation();
    const teamID = location.state;
    const urls = useParams();

    useEffect(() => {
        axios.get("https://admin.btcluk.com/api/teamPlayer/" + teamID).then((res) => {
            const link = urls.club_id + "/" + urls.team_id;
            if (res.data) {
                const playersList = res.data.map((player, id) => <PlayerCard data={player} teamId={teamID} />)
                setOtherPlayers(playersList);
            }
        });
        axios.get("https://admin.btcluk.com/api/playerProfile/" + urls.id).then((res) => {
            setPlayerProfile(res.data);
        });
    }, [urls.id])
    const windowWidth = window.innerWidth > 600 ? true : false;
    function load() {
        document.getElementById('player-profile-loader').style.display = 'none';
    }

    return (

        <div style={{ width: '100%', maxWidth: 'var(--maxWidth)', margin: '0 auto', marginBottom: '100px' }}>
            {windowWidth
                ? <PlayerProfileHeader data={playerProfile} />
                : <MobPlayerProfileHeader data={playerProfile} />
            }
            {/* <div id="player-profile-loader" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '80vh'
            }}>
                <div style={{ textAlign: 'center' }} >
                    <div className="loader" id="team-loader" style={{ margin: '2rem auto' }}></div>

                    <p>We Are Preparing The Player Statistics. Please Wait A Minute. </p>
                </div>
            </div> */}
            <div>
                <PlalyerStat />
            </div>
            <div style={{ display: 'flex', columnGap: '1rem', overflow: 'scroll', marginTop: "2rem" }}>
                {otherPlayers}
            </div>
        </div>
    );
}

export default PlayerProfile;