import PropTypes from 'prop-types';
import playerImg from '../../../images/player.png';
function CommitteeCard({ data }) {
    const styles = {
        card: {
            width: '202px',
            height: '253px',
            background: 'var(--blue)',
            borderRadius: '12px',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center'
        },
        imageWrapper: {
            width: '102px',
            height: '102px',
            background: 'white',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    };
    return (
        <div style={styles.card}>
            <div style={styles.imageWrapper}>
                <img src={"https://admin.btcluk.com/files/" + data.Profile_image} style={{ borderRadius: "50%" }} alt="" width="102px" height="102px" />
            </div>
            <p style={{ font: '800 16px var(--fontInter)', margin: 0, color: 'white' }}>{data.name}</p>
           

            <span style={{ color: 'var(--yellow)',textAlign:'center',padding:'2px' }}>{data.position}</span>
            <span style={{ color: 'var(--yellow)',textAlign:'center',fontSize:'10px' }}>{data.email}</span>

<span style={{ color: 'var(--yellow)',textAlign:'center',fontSize:'10px' }}>{data.phone}</span>
        </div>
    );
}
// CommitteeCard.propTypes = {
//     data: PropTypes.object,
//     name: PropTypes.string
// }
export default CommitteeCard;