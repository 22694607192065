import axios from "axios";
import { useState, useEffect } from "react";
import { useTranslation } from '../../../translation';

function PointsTable() {
    const { t, i18n } = useTranslation();

    const [points, setPoints] = useState([]);
    function fetchPoints(id = 112529) {
        axios.get("https://admin.btcluk.com/api/pointsTable/" + id).then((res) => {
            const pointsList = res.data.map((points, i) => {
                return <tr style={{ height: '40px' }} key={i}>
                    <td style={{ paddingLeft: '1rem' }}>{points.column_1}</td>
                    <td>{points.column_2}</td>
                    <td>{points.column_3}</td>
                    <td>{points.column_4}</td>
                    <td style={{ paddingRight: '1rem', textAlign: 'right' }}>{points.column_13}</td>
                </tr>
            });
            setPoints(pointsList)
        });
    }
    useEffect(() => {
        fetchPoints();
    }, []);
    function handleChange(e) {
        fetchPoints(e.target.value)
    }
    const pointsTblHeader = {
        background: 'var(--blue)',
        color: 'white',
        height: '42px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 1.5rem',
        borderRadius: '12px 12px 0px 0px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
        font: '600 16px var(--fontSSP)'
    };

    const PointsTable = {
        borderCollapse: 'collapse',
        font: '400 16px var(--fontSSP)'
    };

    return (
        <div id="points" style={{ height: "auto" }}>
            <div style={pointsTblHeader}>
                <h3 style={{ margin: 0 }}>{t('points_table')}</h3>
                <div>
                    <select style={{ border: 'none', borderRadius: '12px', paddingLeft: '20px', padding: '5px' }} name="lang" id="lang" onChange={handleChange}>
                        <option value="112529">Premier</option>
                        <option value="112530">Super</option>
                        <option value="112531">Champion</option>
                        <option value="112532">Legends</option>
                        <option value="112533">Conference</option>
                        <option value="112534">Community</option>
                        <option value="112535">Challenge</option>
                        <option value="112560">Classic</option>
                        <option value="112844">Platinum</option>
                    </select>
                </div>
            </div>
            <div style={{ padding: '20px 0', padding: '0px', height: '455px', boxSizing: 'border-box', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)', borderRadius: '0 0 12px 12px' }}>
                <table id="Points-table" style={PointsTable}>
                    <thead>
                        <tr style={{ background: '#F6D330', color: 'white', fontWeight: 600, height: '40px' }}>
                            <th style={{ textAlign: 'left', paddingLeft: '1rem' }}>Team</th>
                            <th>Match</th>
                            <th>W</th>
                            <th>L</th>
                            <th>Pts</th>
                        </tr>
                    </thead>
                    <tbody>
                        {points.length > 0 && points}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default PointsTable;