import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import App from './App';
import reportWebVitals from './reportWebVitals';

if(localStorage.getItem('default_language') == null)
{
  localStorage.setItem('language', 1);
}
const d = localStorage.getItem('default_language') || 'en';


i18n.init({
  interpolation: { escapeValue: false },
  lng: d, // Set the default language
  resources: {
    en: {
      translation: require('./locales/en.json')
    },
    ta: {
      translation: require('./locales/ta.json')
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <I18nextProvider i18n={i18n}>

  <App />
  </I18nextProvider>,

);

reportWebVitals();
