import PageTitle from "../../Components/UIcomponents/PageTitle";
import TeamCard from "../../Components/Team/TeamCard";
import FixtureCard from "../../Components/UIcomponents/FixtureCard";
import MobFixtureCard from "../../Components/MobileComponents/MobFixtureCard";
import Result from "../../Components/UIcomponents/Result";
import MobResultCard from "../../Components/MobileComponents/MobResultCard";
import LeagueTable from "./components/LeagueTable";
import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import noImg from "../../images/noresult.png"
import axios from "axios";
import { useTranslation } from '../../translation';

function PremierTeams() {
    const { t, i18n } = useTranslation();

    const [teams, setTeams] = useState('');
    const [fixtures, setFixtures] = useState('');
    const [results, setResults] = useState('');
    const pathTitle = {
        112844: "PLATINUM",
        112529: "PREMIER",
        112530: "SUPER",
        112531: "CHAMPION",
        112532: "LEGENDS",
        112533: "CONFERENCE",
        112534: "COMMUNITY",
        112535: "CHALLENGE",
        112560: "CLASSIC",
    }
    const leagues = useParams();
    useEffect(() => {
        const windowWidth = window.innerWidth;
        axios.get("https://admin.btcluk.com/api/divisionTeam/" + leagues.id).then((res) => {
            const teamsList = res.data.map((team, i) => <TeamCard data={team} league={pathTitle[leagues.id]} key={i} />);
            const data = teamsList ? teamsList : <img src={noImg} style={{ textAlign: 'center' }} />
            setTeams(data);
        });
        axios.get("https://admin.btcluk.com/api/divisionFixture/" + leagues.id).then((res) => {
            let fixturesList = '';
            if (windowWidth > 600) {
                fixturesList = res.data.map((team, i) => <FixtureCard data={team} key={i} />);
            } else {
                fixturesList = res.data.map((team, i) => <MobFixtureCard data={team} key={i} />);
            }
            const data = fixturesList ? fixturesList : <img src={noImg} style={{ textAlign: 'center' }} />
            setFixtures(data);
        });
        axios.get("https://admin.btcluk.com/api/divisionResult/" + leagues.id).then((res) => {
            let resultList = '';
            if (windowWidth > 600) {
                resultList = res.data.map((team, i) => <Result data={team} key={i} />);
            } else {
                resultList = res.data.map((team, i) => <MobResultCard data={team} key={i} />);
            }
            const data = resultList ? resultList : <img src={noImg} style={{ textAlign: 'center' }} />
            setResults(data);
        });

    }, []);
    function openTab(e, id) {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("leagueTabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        const styleProp = id == "Fixtures" ? "flex" : 'block';
        document.getElementById(id).style.display = styleProp
        e.currentTarget.className += " active";
    }
    function load() {
        document.getElementById('league-loader').style.display = 'none';
    }
    const styles = {
        tab: {
            maxWidth: 'var(--maxWidth)',
            boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
            borderRadius: '12px'
        },
        tabBtnWrapper: {
            background: 'var(--blue)',
            boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
            borderRadius: '12px',
            display: 'grid',
            justifyContent: 'space-between',
            marginBottom: '2rem',
        },
        Btn: {
            border: 'none',
            background: 'inherit',
            font: '800 16px var(--fontInter)',
            cursor: 'pointer'
        },
        TeamsWrapper: {
            display: 'grid',
            rowGap: '2rem'
        }
       
    };

    
    return (
        <div>
            <PageTitle name={pathTitle[leagues.id]} />
            <div style={{ margin: '2rem auto', maxWidth: 'var(--maxWidth)' }}>
                <div id="division-tab" style={styles.tab} onLoad={load}>
                    <div id="tap-btn-wrapper" style={styles.tabBtnWrapper}>
                        <button className="tablinks active" style={styles.Btn} onClick={(e) => openTab(e, 'Teams')}>{t('div_teams')}</button>
                        <button className="tablinks" style={styles.Btn} onClick={(e) => openTab(e, 'Fixtures')}>{t('div_fixtures')}</button>
                        <button className="tablinks" style={styles.Btn} onClick={(e) => openTab(e, 'result')}>{t('div_results')}</button>
                        <button className="tablinks" style={styles.Btn} onClick={(e) => openTab(e, 'LeagueTable')}>{t('div_league_table')}</button>
                        {/* <button className="tablinks" style={styles.Btn} onClick={(e) => openTab(e, 'Teams')}>STATISTICS</button> */}
                    </div>
                    <div className="loader" id="league-loader" style={{ margin: 'auto' }}></div>
                    <div id="Teams" className='leagueTabcontent' style={{ padding: '0 6rem 2rem 6rem' }}>
                        <div id='teams' style={styles.TeamsWrapper}>
                            {teams}
                        </div>
                    </div>
                    <div id="Fixtures" className='leagueTabcontent' style={{ display: 'none', padding: '.5rem', flexFlow: 'column', alignItems: 'center' }}>
                        {fixtures}
                    </div>
                    <div id="result" className='leagueTabcontent' style={{ display: 'none', padding: '.5rem' }}>
                        {results}
                    </div>
                    <div id="LeagueTable" className='leagueTabcontent' style={{ display: 'none', padding: '.5rem' }}>
                        <LeagueTable id={leagues.id} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PremierTeams;