import { useEffect, useState } from "react";
import PlayerCard from "../Components/Player/PlayerCard";
import TeamProfileHeader from '../Components/Team/TeamProfileHeader';
import MobTeamProfileHeader from "../Components/MobileComponents/MobTeamProfileHeader";
import axios from "axios";
import { useParams } from "react-router-dom";

function TeamProfile(props) {
    const [teamProfile, setTeamProfile] = useState('')
    const [players, setPlayers] = useState('');
    const urls = useParams();
    const windowWidth = window.innerWidth > 600 ? true : false;

    useEffect(() => {
        axios.get("https://admin.btcluk.com/api/TeamProfile/" + urls.team_id).then((res) => {
            setTeamProfile(res.data);
        });
        axios.get("https://admin.btcluk.com/api/teamPlayer/" + urls.team_id).then((res) => {
            const link = urls.club_id + "/" + urls.team_id;
            if (res.data) {
                const playersList = res.data.map((player, id) => <PlayerCard data={player} teamId={urls.team_id} />)
                setPlayers(playersList);
            }
        });

    }, [])
    function load() {
        document.getElementById('team-loader').style.display = 'none';
    }
    const styles = {
        TeamPlayerContainer: {
            display: 'grid',
            rowGap: '2rem',
            columnGap: '6.5rem',
            justifyContent: 'center',
            margin: ' 2rem auto'
        }
    }

    return (
        <div>
            {windowWidth
                ? <TeamProfileHeader data={teamProfile} />
                : <MobTeamProfileHeader data={teamProfile} />
            }
            <div className="loader" id="team-loader" style={{ margin: '2rem auto' }}></div>
            <div id="team-player-container" style={styles.TeamPlayerContainer} onLoad={load}>
                {players}
            </div>
        </div>
    );
}

export default TeamProfile;