import playerProfileImg from '../../images/player-profile.png';

function PlayerProfileHeader({ data }) {
    const styles = {
        TeamDtsContainer: {
            maxWidth: 'var(--maxWidth)',
            height: '396px',
            background: 'var(--yellow)',
            display: 'flex',
            padding: "48px 24px 28px 24px",
            justifyContent: 'space-between',
            boxSizing: 'border-box'
        },
        imageWrapper: {
            width: '202px',
            height: '202px',
            background: 'white',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        plalyerStatsWrapper: {
            width: '412px',
            height: '59px',
            display: 'flex',
            color: 'var(--blue)',
            background: 'white',
            border: '1px solid var(--blue)',
            borderRadius: '42px',
            justifyContent: 'space-around',
            alignItems: 'center',
            font: '700 20px var(--fontSSP)',
            padding:'10px'
        },
        m_0: {
            margin: 0
        },
        m_0_l: {
            margin: 0,
            color:'var(--yellow)'
        },
        m_t_24: {
            marginTop: 24
        },
    };

    return (
        <div style={styles.TeamDtsContainer} id="TeamDetails">
            <div style={{ display: 'flex', width: "100%", columnGap: '1rem' }}>
                <div style={styles.imageWrapper}>
                    <img src={data.Photo ? "https://admin.btcluk.com/players/" + data.Photo : playerProfileImg} alt="" style={{ width: "100%", height: "100%", borderRadius: '50%' }} />
                </div>
                <div style={{ display: 'flex', flexFlow: 'column', justifyContent: 'end' }}>
                    <div style={{ display: 'flex', height: '100%', flexFlow: 'column', justifyContent: 'center' }}>
                        <h1 style={{ color: 'white', margin: 0, font: '800 2rem var(--fontInter)' }}>{data.Forename} {data.Surname}</h1>
                    </div>
                 <div style={{ color: 'white', display: 'flex', columnGap: '32px', font: '700 1rem var(--fontSSP)' }}>
                        <div style={{ display: 'flex', flexFlow: 'column', rowGap: '24px' }}>
                        <p style={styles.m_0}>Player ID : {data.PlayerID}</p>
                            <p style={styles.m_0}>Batting Style : {data.BatStyle ? data.BatStyle : 'Not Added' }</p>
                            <p style={styles.m_0}>Bowling Style : {data.BowlStyle ? data.BowlStyle : 'Not Added'}</p>
                        </div>
                        <div style={{ display: 'flex', flexFlow: 'column', rowGap: '24px' }}>
                            <p style={styles.m_0}>Age Group : {data.AgeGroup ? data.AgeGroup : 'Not Added'}</p>
                            <p style={styles.m_0}>Registered On : {data.created_date ? data.created_date : 'Not Added'}</p>
                            {/* <p style={styles.m_0}>DOB : {data.DOB ? data.DOB : 'Not Added'}</p> */}
                            <p style={styles.m_0}>Player Type : {data.player_type ? data.player_type : 'Not Added'}</p>

                        </div>
                        <div style={{ display: 'flex', flexFlow: 'column', rowGap: '24px' }}>
                            <p style={styles.m_0}>Play Cricket Id : {data.pc_player_id ? data.pc_player_id : 'Not Added'}</p>
                            <p style={styles.m_0}>Nickname : {data.Nickname ? data.Nickname : 'Not Added'}</p>

                        </div>
                      
                    </div>
                </div>
            </div>
            <div style={styles.plalyerStatsWrapper}>
                <div>
                    <p style={styles.m_0}>{data.total_matchs ? data.total_matchs : 0}</p>
                    <p style={styles.m_0_l}>Matches</p>
                </div>
                <div>
                    <p style={styles.m_0}>{data.total_runs ? data.total_runs : 0}</p>
                    <p style={styles.m_0_l}>Runs</p>
                </div>
                <div>
                    <p style={styles.m_0}>{data.total_wkts ? data.total_wkts : 0}</p>
                    <p style={styles.m_0_l}>Wickets</p>
                </div>
            </div>
        </div>
    );
}

export default PlayerProfileHeader;