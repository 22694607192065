import { Link } from 'react-router-dom';
import PageTitle from '../../Components/UIcomponents/PageTitle';
import AdsSection from '../home/components/AdsSection';
import PointsTable from '../home/components/PointsTable';
import News from '../home/components/News';
import FeaturedVideos from '../home/components/FeaturedVideos';
import Summery from '../home/components/Summery';
import BtclPartner from '../home/components/BtclPartners';
import {  useState } from 'react';

function Leagues() {
    const [isHovered1, setIsHovered1] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const [isHovered3, setIsHovered3] = useState(false);
    const [isHovered4, setIsHovered4] = useState(false);
    const [isHovered5, setIsHovered5] = useState(false);
    const [isHovered6, setIsHovered6] = useState(false);
    const [isHovered7, setIsHovered7] = useState(false);
    const [isHovered8, setIsHovered8] = useState(false);
    const [isHovered9, setIsHovered9] = useState(false);

    const handleHover1 = () => {
        setIsHovered1(true);
      };
      const handleMouseLeave1 = () => {
        setIsHovered1(false);
      };

      const handleHover2 = () => {
        setIsHovered2(true);
      };
      const handleMouseLeave2 = () => {
        setIsHovered2(false);
      };

      const handleHover3 = () => {
        setIsHovered3(true);
      };
      const handleMouseLeave3 = () => {
        setIsHovered3(false);
      };

      const handleHover4 = () => {
        setIsHovered4(true);
      };
      const handleMouseLeave4 = () => {
        setIsHovered4(false);
      };

      const handleHover5 = () => {
        setIsHovered5(true);
      };
      const handleMouseLeave5 = () => {
        setIsHovered5(false);
      };

      const handleHover6 = () => {
        setIsHovered6(true);
      };
      const handleMouseLeave6 = () => {
        setIsHovered6(false);
      };

      const handleHover7 = () => {
        setIsHovered7(true);
      };
      const handleMouseLeave7 = () => {
        setIsHovered7(false);
      };

      const handleHover8 = () => {
        setIsHovered8(true);
      };
      const handleMouseLeave8 = () => {
        setIsHovered8(false);
      };

      const handleHover9 = () => {
        setIsHovered9(true);
      };
      const handleMouseLeave9 = () => {
        setIsHovered9(false);
      };

    const styles = {
        leaguesBtn: {
            textDecoration: 'none',
            
        }
    }

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };


    return (
        <div>


            <div style={{ boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)', padding: '2rem .5rem', margin: '2rem 1.5rem', borderRadius: '12px' }}>
                <div id="Leagues-wrapper-Container" style={{ margin: '2rem auto' }}>
                    <div id='Leagues-wrapper'>
                        <Link style={styles.leaguesBtn} onMouseLeave={handleMouseLeave1} onMouseEnter={handleHover1} onClick={handleScrollToTop} to="112529"><div className="leagues" style={{transition: 'transform  0.3s ease-in-out , color  0.5s ease-in-out  ',transform: isHovered1 ? 'scale(1.06)' : 'scale(1)' ,  color : isHovered1 ? 'var(--yellow)' : 'white' }}>PREMIER</div></Link>
                        <Link style={styles.leaguesBtn} onMouseLeave={handleMouseLeave2} onMouseEnter={handleHover2} onClick={handleScrollToTop} to="112530"><div className="leagues" style={{transition: 'transform  0.3s ease-in-out , color  0.5s ease-in-out ',transform: isHovered2 ? 'scale(1.06)' : 'scale(1)' ,  color : isHovered2 ? 'var(--yellow)' : 'white'}}>SUPER</div></Link>
                        <Link style={styles.leaguesBtn} onMouseLeave={handleMouseLeave3} onMouseEnter={handleHover3} onClick={handleScrollToTop} to="112531"><div className="leagues" style={{transition: 'transform  0.3s ease-in-out , color  0.5s ease-in-out ',transform: isHovered3 ? 'scale(1.06)' : 'scale(1)' ,  color : isHovered3 ? 'var(--yellow)' : 'white'}}>CHAMPION</div></Link>
                        <Link style={styles.leaguesBtn} onMouseLeave={handleMouseLeave4} onMouseEnter={handleHover4} onClick={handleScrollToTop} to="112532"><div className="leagues" style={{transition: 'transform  0.3s ease-in-out , color  0.5s ease-in-out ',transform: isHovered4 ? 'scale(1.06)' : 'scale(1)' ,  color : isHovered4 ? 'var(--yellow)' : 'white'}}>LEGENDS</div></Link>
                        <Link style={styles.leaguesBtn} onMouseLeave={handleMouseLeave5} onMouseEnter={handleHover5} onClick={handleScrollToTop} to="112533"><div className="leagues" style={{transition: 'transform  0.3s ease-in-out , color  0.5s ease-in-out ',transform: isHovered5 ? 'scale(1.06)' : 'scale(1)' ,  color : isHovered5 ? 'var(--yellow)' : 'white'}}>CONFERENCE</div></Link>
                        <Link style={styles.leaguesBtn} onMouseLeave={handleMouseLeave6} onMouseEnter={handleHover6} onClick={handleScrollToTop} to="112534"><div className="leagues" style={{transition: 'transform  0.3s ease-in-out , color  0.5s ease-in-out ',transform: isHovered6 ? 'scale(1.06)' : 'scale(1)' ,  color : isHovered6 ? 'var(--yellow)' : 'white'}}>COMMUNITY</div></Link>
                        <Link style={styles.leaguesBtn} onMouseLeave={handleMouseLeave7} onMouseEnter={handleHover7} onClick={handleScrollToTop} to="112535"><div className="leagues" style={{transition: 'transform  0.3s ease-in-out , color  0.5s ease-in-out ',transform: isHovered7 ? 'scale(1.06)' : 'scale(1)' ,  color : isHovered7 ? 'var(--yellow)' : 'white'}}>CHALLENGE</div></Link>
                        <Link style={styles.leaguesBtn} onMouseLeave={handleMouseLeave8} onMouseEnter={handleHover8} onClick={handleScrollToTop} to="112560"><div className="leagues" style={{transition: 'transform  0.3s ease-in-out , color  0.5s ease-in-out ',transform: isHovered8 ? 'scale(1.06)' : 'scale(1)' ,  color : isHovered8 ? 'var(--yellow)' : 'white'}}>CLASSIC</div></Link>
                        <Link style={styles.leaguesBtn} onMouseLeave={handleMouseLeave9} onMouseEnter={handleHover9} onClick={handleScrollToTop} to="112844"><div className="leagues" style={{transition: 'transform  0.3s ease-in-out , color  0.5s ease-in-out ',transform: isHovered9 ? 'scale(1.06)' : 'scale(1)' ,  color : isHovered9 ? 'var(--yellow)' : 'white'}}>PLATINUM</div></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Leagues;