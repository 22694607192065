import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../../translation';

function Summery() {
    const { t, i18n } = useTranslation();
    const [leaguesCount, setLeaguesCount] = useState(0);
    const [teamsCount, setTeamCount] = useState(0);
    const [playerCount, setPlayerCount] = useState(0);
    const [ClubCount, setclubCount] = useState(0);
    const [summary, setSummary] = useState(0);

    useEffect(() => {
        axios.get("https://admin.btcluk.com/api/count").then((res) => {
            setclubCount(res.data.clubCount)
            setLeaguesCount(res.data.divisionCount);
            setTeamCount(res.data.teamsCount);
            setPlayerCount(res.data.playersCount)
        });
    }, []);

    useEffect(() => {
    const language = localStorage.getItem('language');
    axios.get("https://admin.btcluk.com/api/summary/" + language)
        .then((res) => {
            setSummary(res.data.summary_text)

        });
    }, []);

    const styles = {
        container: {
            background: 'var(--blue)',
            color: 'white',
            padding: '2rem 1rem',
            borderRadius: '1rem',
            fontFamily: 'var(--fontSSP)'
        },
        firstRow: {
            height: '150px',
            display: 'flex',
            background: 'white',
            borderRadius: '2rem',
            flexFlow: 'column',
            justifyContent: 'center',
            textAlign: 'center',

        },

        secondRow: {
            width: '255px',
            height: '202px',
            display: 'flex',
            background: 'white',
            borderRadius: '2rem',
            flexFlow: 'column',
            justifyContent: 'center',
            textAlign: 'center',
        },

        teamRegisterBtn: {
            padding: '7px 25px',
            color: 'white',
            textDecoration: 'none',
            background: 'var(--yellow)',
            borderRadius: '21px',
            border:'none',
        },
        btclConditionBtn: {
            padding: '7px 25px',
            color: 'white',
            textDecoration: 'none',
            color: 'var(--yellow)',
            background: 'white',
            borderRadius: '21px',
        }
    };
    return (
        <div id="summery-container" style={styles.container}>
            <h2 style={{ textAlign: 'center', marginBottom: '2rem', fontWeight: 600, fontSize: '36px' }}>{t('summary')} <span style={{color:'var(--yellow)'}}> BTCL</span></h2>
            <div id="summery" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div id='Summery-leagues' className='summery-counts' style={styles.firstRow}>
                    <h2 style={{ fontWeight: 700, fontSize: '48px', color: ' #8A060D' }}>{leaguesCount}</h2>
                    <h2 style={{ fontWeight: 400, fontSize: '36px', color: ' #8A060D' }}>{t('sum_divisions')}</h2>
                </div>
                <div id='Summery-teams' className='summery-counts' style={styles.firstRow}>
                    <h2 style={{ fontWeight: 700, fontSize: '48px', color: '#8A8306' }}>{teamsCount}</h2>
                    <h2 style={{ fontWeight: 400, fontSize: '36px', color: '#8A8306' }}>{t('sum_teams')}</h2>
                </div>
                <div id='Summery-player' className='summery-counts' style={styles.firstRow}>
                    <h2 style={{ fontWeight: 700, fontSize: '48px', color: '#068A41' }}>{playerCount}</h2>
                    <h2 style={{ fontWeight: 400, fontSize: '36px', color: '#068A41' }}>{t('sum_players')}</h2>
                </div>
                <div id='Summery-player' className='summery-counts' style={styles.firstRow}>
                    <h2 style={{ fontWeight: 700, fontSize: '48px', color: '#068A41' }}>{ClubCount}</h2>
                    <h2 style={{ fontWeight: 400, fontSize: '36px', color: '#068A41' }}>{t('sum_clubs')}</h2>
                </div>
            </div>
            {/* <div id='Summery-four' style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
                <div style={styles.secondRow}>
                    <h2 style={{ fontWeight: 700, fontSize: '48px', color: '#41068A' }}>100</h2>
                    <h2 style={{ fontWeight: 400, fontSize: '36px', color: '#41068A' }}>Fours</h2>
                </div>
                <div id='Summery-sixes' style={styles.secondRow}>
                    <h2 style={{ fontWeight: 700, fontSize: '48px', color: '#068A41' }}>50</h2>
                    <h2 style={{ fontWeight: 400, fontSize: '36px', color: '#068A41' }}>Sixes</h2>
                </div>
                <div id='Summery-fifties' style={styles.secondRow}>
                    <h2 style={{ fontWeight: 700, fontSize: '48px', color: '#060D8A' }}>10</h2>
                    <h2 style={{ fontWeight: 400, fontSize: '36px', color: '#060D8A' }}>Fifties</h2>
                </div>
                <div id='Summery-conturies' style={styles.secondRow}>
                    <h2 style={{ fontWeight: 700, fontSize: '48px', color: '#8A060D' }}>10</h2>
                    <h2 style={{ fontWeight: 400, fontSize: '36px', color: '#8A060D' }}>Centuries</h2>
                </div>
            </div> */}
            <p style={{ textAlign: 'center', fontWeight: 600, fontSize: '16px', margin: '2rem 0' }}>
            <div dangerouslySetInnerHTML={{ __html: summary }} style={{  font: '400 1rem var(--fontSSP)' }}></div>
            </p>
            <div id="summery-btn" style={{ textAlign: 'center', fontWeight: 600, fontSize: '14px' }}>
                <button style={styles.teamRegisterBtn}><a href="https://admin.btcluk.com/team/login" style={{color:'white',    fontWeight:'700'       , textDecoration: 'none',}} target='_blank' >Team Login</a></button>
                <Link to="/who-we-are/0" style={styles.btclConditionBtn} onClick={() => window.scrollTo(0, 0)}>About BTCL </Link>
            </div>
        </div>
    );
}

export default Summery;