export default function Slide({ data }) {
    const mySlides = {
        display: "none",
        height: '100%'
    };
    const text = {
        color: "var(--white)",
        position: "absolute",
        bottom: '20%',
        width: "100%",
        textAlign: "center",
        height: 'fit-content'
    };
    return (
        <div style={mySlides} className="slides">
            <img src={"https://admin.btcluk.com/files/" + data.image} style={{ width: '100%', height: '100%' }} />
            <div style={text}>
                <h1 id="slide-heading" style={{ textAlign: "center", fontFamily: "var(--fontSSP)", fontWeight: 600 }}>{data.heading}</h1>
                <p style={{ fontSize: "16px", fontFamily: "var(--fontSSP)", fontWeight: 400, margin: 0 }}>{data.sub_text}</p>
            </div>
        </div>
    );
}