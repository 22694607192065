import logo from '../../../images/BTCL - Playing Conditions v13 - 2023 - English.pdf';


function PlayingConditions(props) {
    const styles = {
        visionPointsWrapper: {
            display: 'flex',
        },
        visionPointsNo: {
            marginRight: '2rem'
        }
    };
    return (
        <div >
            <div id="PlayingConditions" className='tabcontent' style={{ padding: '0 6rem', display: 'none', paddingBottom: '2rem' }}>
                {/* <div dangerouslySetInnerHTML={{ __html: props.data }} style={{ font: '400 1rem var(--fontSSP)' }}></div> */}
                {/* <embed src={"https://admin.btcluk.com/files/" + props.data} type="" style={{ width: '100%', height: '100vh' }} /> */}
                <embed src={logo} type="" style={{ width: '100%', height: '100vh' }} />

            </div>
        </div>
    );
}

export default PlayingConditions;