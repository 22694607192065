import { useEffect, useState } from "react";
import FeaturedVidCard from "./FeaturedVidCard";
import axios from "axios";
import { useTranslation } from '../../../translation';

function FeaturedVideos() {
    const { t, i18n } = useTranslation();

    const [video, setVideo] = useState('');
    useEffect(() => {
        const videosContainer = document.getElementById('videos-container');
        const videosContainerWidth = parseInt(getComputedStyle(videosContainer).width);
        let width = videosContainerWidth;
        if (600 < videosContainerWidth) width = parseInt((videosContainerWidth - 64) / 3);
        const language = localStorage.getItem('language');
        axios.get("https://admin.btcluk.com/api/vedios/" + language).then((res) => {
            const videoList = res.data.vedios.map((video, i) => {
                return <FeaturedVidCard data={video} size={width} key={i} />
            });
            setVideo(videoList)
        })
    }, [])
    function initVideos() {
        const videosContainer = document.getElementById('videos-container');
        // slider = document.getElementById('sliderWrapper');
        // SliderWidth = parseInt(getComputedStyle(slider).width);
        const videosContainerWidth = parseInt(getComputedStyle(videosContainer).width);
    }
    return (
        <div style={{ marginBottom: '6rem' }} onLoad={initVideos}>
            <h2 style={{ font: '700 24px var(--fontSSP)', color: 'var(--blue)' }}>{t('videos')}</h2>
            <div id="videos-container" style={{
                display: 'flex', columnGap: '2rem', overflow: 'scroll'
            }} onLoad={initVideos}>
                {video}
            </div>
        </div>
    );
}

export default FeaturedVideos;