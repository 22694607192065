import { Link } from 'react-router-dom';
import bgImg from '../../images/teamProfileBg.png';
import playerImg from '../../images/teamProfileImg.png';
import playerProfileImg from '../../images/player-profile.png';
function MobPlayerProfileHeader({ data }) {
    const styles = {
        TeamDtsContainer: {
            width: '100%',
            height: '550px',
            backgroundColor: "var(--yellow)",
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            boxSizing: 'border-box',
            padding: '.5rem'
        },
        imageWrapper: {
            width: '150px',
            height: '150px',
            background: 'white',
            borderRadius: '50%',
            marginBottom:'10px',
            marginLeft:'auto',
            marginRight:'auto'
        },
        TeamPlayerContainer: {
            width: '1232px',
            display: 'grid',
            gridTemplateColumns: 'auto auto auto auto',
            rowGap: '2rem',
            columnGap: '6.5rem',
            justifyContent: 'center',
            margin: 'auto'
        },
        btn: {
            textDecoration: 'none',
            color: 'white',
            background: 'var(--blue)',
            padding: '7px 44px',
            borderRadius: '1rem',
            font: '600 14px var(--fontSSP)',
            width: 'fit-content',
            float: 'center',
            marginLeft:'auto',
            marginRight:'auto'

        },
        m_0: {
            margin: 0
        },
        m_0_l: {
            margin: 0,
            color:'var(--yellow)'
        },
        m_t_24: {
            marginTop: 24
        },
        plalyerStatsWrapper: {
            width: 'auto',
            height: 'auto',
            display: 'flex',
            color: 'var(--blue)',
            background: 'white',
            border: '1px solid var(--blue)',
            borderRadius: '42px',
            justifyContent: 'space-around',
            alignItems: 'center',
            font: '700 15px var(--fontSSP)',
            padding:'4px'
        }
    };
    return (
      

        
        <div style={styles.TeamDtsContainer} id="TeamDetails">
        <div style={{ display: 'block', width: '100%',  alignItems: 'center' ,textAlign:'center'}}>
            <div style={styles.imageWrapper}>
                <img src={data.Photo ?  "https://admin.btcluk.com/players/" + data.Photo : playerProfileImg} alt="" width="100%" height="100%" style={{ borderRadius: "50%" }} />
            </div>
           
            <div style={{ color: 'white', display: 'flex', flexFlow: 'column', rowGap: ' 1rem ' }}>
                <h1 style={{ font: '800 1.5rem var(--fontInter)', margin: 0 }}>{data.Forename}<br></br> {data.Surname}</h1>
                <div style={styles.plalyerStatsWrapper}>
                <div>
                    <p style={styles.m_0}>{data.total_matchs ? data.total_matchs : 0}</p>
                    <p style={styles.m_0_l}>Matches</p>
                </div>
                <div>
                    <p style={styles.m_0}>{data.total_runs ? data.total_runs : 0}</p>
                    <p style={styles.m_0_l}>Runs</p>
                </div>
                <div>
                    <p style={styles.m_0}>{data.total_wkts ? data.total_wkts : 0}</p>
                    <p style={styles.m_0_l}>Wickets</p>
                </div>
            </div>
                <h2 style={{ font: '600 1rem var(--fontInter)', margin: 0 }}>Player ID : {data.PlayerID}</h2>
                            <p style={styles.m_0}>Batting Style : {data.BatStyle}</p>
                            <p style={styles.m_0}>Bowling Style : {data.BowlStyle}</p>
                        
                            <p style={styles.m_0}>Age Group : {data.AgeGroup}</p>
                            <p style={styles.m_0}>Registered On : {data.created_date ? data.created_date : 'Not Added'}</p>
                            {/* <p style={styles.m_0}>DOB : {data.DOB}</p> */}

                    
               
                            
            </div>
        </div>
    </div>


    );
}

export default MobPlayerProfileHeader;