import { useEffect, useState } from 'react';
import partner from '../../../images/partner/partner.png';
import axios from 'axios';
function BtclPartner() {
    const [partners, setPartners] = useState('');
    useEffect(() => {
        axios.get("https://admin.btcluk.com/api/partners").then((res) => {
            const partnersList = res.data.partners.map((partner, i) => {
                return <img src={"https://admin.btcluk.com/files/" + partner.logo} height="40rem" width="auto" key={i} />
            })
            setPartners(partnersList);
        });
    }, []);
    return (
        <div style={{ textAlign: 'center', marginTop: '2rem' }}>
            <h2 style={{ color: 'var(--blue)' }}>
                BTCL SPONSORS
            </h2>
            <div id="btcl-partner" style={{ display: 'flex',marginBottom:'2rem' }}>
                {partners}
            </div>
        </div>
    );
}

export default BtclPartner;