import { Link } from 'react-router-dom';
import teamLogo from '../../images/team-logo.png';
import teamLogo2 from '../../images/team-logo2.png';
import calandarIcon from '../../images/calendar-icon.png';
import locationIcon from '../../images/location-icon.png';
function Result({ data }) {
    const styles = {
        card: {
            width: '700px',
            height: '230px',
            background: 'var(--blue)',
            borderRadius: '12px',
            marginBottom: '1rem',
            boxSizing: 'border-box',
            padding: '.5rem',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: "space-around"
        },
        imageWrapper: {
            width: '102px',
            height: '102px',
            background: 'white',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden'
        },
        TeamsWrapper: {
            display: 'flex',
            justifyContent: 'center',
            columnGap: '1rem',
            color: 'white'
        },
        btn: {
            textDecoration: 'none',
            font: '400 16px var(--fontSSP)',
            color: 'var(--blue)',
            background: 'white',
            padding: '.4rem 3.2rem',
            borderRadius: '16px'
        },
        title: {
            background: 'var(--yellow)',
            color: ' var(--blue)',
            width: 'fit-content',
            margin: '0 auto',
            padding: '.4rem 1rem',
            borderRadius: '1rem',
            font: '600 1rem var(--fontSSP)'
        }
    };
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
    return (
        <div style={styles.card}>
            <div>
                <span style={{ float: 'left', color: 'var(--yellow)', font: '600 1rem var(--fontSSP)', marginLeft: '1rem' }}> {data.id}</span>
                <span style={{ float: 'right', color: 'var(--yellow)', font: '600 1rem var(--fontSSP)', marginRight: '1rem' }}> {data.math_date}</span>
                <p style={styles.title}>{data.msg}</p>
            </div>
            <div style={styles.TeamsWrapper}>
                <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem', alignItems: 'center', width: '300px' }}>
                    <div style={styles.imageWrapper}>
                        <img src={data.left_team_logo ? "https://admin.btcluk.com/logo/" + data.left_team_logo : teamLogo} alt="" width="100%" height="100%" />
                    </div>
                    <div>
                        <p style={{ width: '120px', font: '600 1rem var(--fontSSP)' }}>{data.left_club}-{data.left_team}</p>
                        <span>{data.left_run !== '' ? data.left_run + ' / ' : ''}{data.left_w}</span>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ font: 'italic 600 36px var(--fontSSP)' }}>VS</span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem', alignItems: 'center', width: '300px' }}>
                    <div style={styles.imageWrapper}>
                        <img src={data.right_team_logo ? "https://admin.btcluk.com/logo/" + data.right_team_logo : teamLogo} alt="" width="100%" height="100%" />
                    </div>
                    <div>
                        <p style={{ width: '120px', font: '600 1rem var(--fontSSP)' }}>{data.right_club}-{data.right_team}</p>
                        <span>{data.right_run !== '' ? data.right_run + ' / ' : ''}{data.right_w}</span>
                    </div>
                </div>

            </div>
            {/* <div>
                <div style={{ display: 'flex' }}>
                    <span>
                        <img src={calandarIcon} alt="" />
                    </span>
                    <span>
                        data.match_date
                    </span>
                </div>
                <div style={{ display: 'flex' }}>
                    <span>
                        <img src={locationIcon} alt="" />
                    </span>
                    <span style={{ width: '242px', display: 'inline-block' }}>
                        data.ground_name
                    </span>
                </div>
            </div> */}

<div style={{ textAlign: 'center' }}>
                {data.commend &&
                    <Link to={"/view-score-card/" + data.id} style={{
                         font: '600 1rem var(--fontSSP)',
                        textDecoration: 'none'
                    }}> <span style={{ color: 'red' }}>Penalty Applied</span></Link>
                }
            </div>

            <div style={{ textAlign: 'center' }}>
                {data.msg != "Abandoned" &&
                    <Link to={"/view-score-card/" + data.id} style={{
                        backgroundColor: '#0a7d0a', font: '600 1rem var(--fontSSP)',
                        textDecoration: 'none', padding: '5px 10px', borderRadius: '15px'
                    }} onClick={handleScrollToTop}> <span style={{ color: 'white' }}>view score card</span></Link>
                }
            </div>

            

        </div >
    );
}

export default Result;