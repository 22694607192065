function Discipline(props) {
    const mobileScreen = 600;
    const desktopScreen = 1200;
    const styles = {
        visionPointsWrapper: {
            display: 'flex',
        },
        visionPointsNo: {
            marginRight: '2rem'
        },

        styletab:{

            padding: '0 3.7rem', 
            display: 'none', 
            paddingBottom: '2rem'
        }
    };
    if (window.innerWidth <= mobileScreen) {
        styles.styletab.padding = '0 1rem';
        
    }
    return (
        <div>
            <div id="Discipline" className='tabcontent' style={styles.styletab}>
                <div dangerouslySetInnerHTML={{ __html: props.data }} style={{  font: '400 1rem var(--fontSSP)' }}></div>
            </div>
        </div>
    );
}

export default Discipline;