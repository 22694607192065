import { useEffect, useState } from "react";
import axios from "axios";
function LeagueTable({ id }) {
    const [row, setRow] = useState('No data');
    useEffect(() => {
        axios.get("https://admin.btcluk.com/api/divisionLeague/" + id).then((res) => {
            const data = res.data;
            const tableList = data.map((item, i) => {
                return (
                    < tr key={i} style={{ height: '40px' }}>
                        <td style={{ paddingLeft: '1rem' }}>{item.team_name}</td>
                        <td>{item.p}</td>
                        <td>{item.w}</td>
                        <td>{item.i}</td>
                        <td>{item.c}</td>
                        <td>{item.a}</td>
                        <td>{item.lcn}</td>
                        <td>{item.wcn}</td>
                        <td>{item.t}</td>
                        <td>{item.BP}</td>
                        <td>{item.Pen}</td>
                        <td>{item.NRR}</td>
                        <td>{item.Pts}</td>
                    </tr >
                );
            });
            setRow(tableList);
        });
    }, []);
    const pointsTblFooter = {
        background: 'var(--blue)',
        color: 'white',
        height: '42px',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: '0 1.5rem',
        borderRadius: '0px 0px 12px 12px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
        font: '400 9px var(--fontSSP)'
    };

    const PointsTable = {
        width: '100%',
        borderCollapse: 'collapse'
    };

    return (
        <div style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)' }}>
            <table id="Points-table" style={PointsTable}>
                <tr style={{ background: '#F6D330', color: 'white', fontWeight: 600, height: '40px' }}>
                    <th style={{ width: '40%' }}>Team</th>
                    <th>P</th>
                    <th>W</th>
                    <th>I</th>
                    <th>C</th>
                    <th>A</th>
                    <th>ICN</th>
                    <th>WCN</th>
                    <th>T</th>
                    <th>BP</th>
                    <th>Pen</th>
                    <th>NRR</th>
                    <th>Pts</th>
                </tr>
                {row}
                <tr>
                    <td colSpan="13">
                        <div style={pointsTblFooter}>
                            <p>
                                p - Played, w - Won (20), l - Lost (0), c - Cancelled (5), a - Abandoned (5), lcn - Team Conceded (0), wcn - Opposition Conceded (20), t - Tied (10), BP - Bonus Points, Pen - Penalty Points, NRR - Net run rate, Pts - Points
                            </p>
                        </div>
                    </td>

                </tr>
            </table>

            {/* <div style={pointsTblFooter}>
                <p>
                    p - Played, w - Won (20), l - Lost (0), c - Cancelled (5), a - Abandoned (5), lcn - Team Conceded (0), wcn - Opposition Conceded (20), t - Tied (10), BP - Bonus Points, Pen - Penalty Points, NRR - Net run rate, Pts - Points
                </p>
            </div> */}
        </div>
    );
}

export default LeagueTable;