import CommitteeCard from "./CommitteeCard";
import axios from "axios";
import { useEffect, useState } from "react";

function Committee() {
    const [committees, setCommittees] = useState('')
    useEffect(() => {
        const language = localStorage.getItem('language');
        axios.get("https://admin.btcluk.com/api/committees").then((res) => {
            const data = res.data.committees;
            const committees = data.map((item) => {
                let key = 1;
                return <CommitteeCard data={item} key={key++} />
            });
            setCommittees(committees)
        });

    }, []);
    const styles = {
        cardWrapper: {
            display: 'grid',
            padding: '0 6rem',
            rowGap: '2rem',
            paddingBottom: '2rem'
        },
    };
    return (
        <div id="Committee" className='tabcontent' style={{ display: 'none' }}>
            <div id="committees-wrapper" style={styles.cardWrapper}>
                {committees}
            </div>
        </div>
    );
}

export default Committee;