function PageTitle(prop) {
    return(
        <div>
            <div style={{background:'var(--yellow)',padding:'.5rem 0',margin:'2rem 0'}}>
                <div style={{margin:'0 auto',maxWidth:'var(--maxWidth)'}}>
                    <h1 style={{font:'700 24px var(--fontSSP)',color:'var(--blue)',margin:'0 1.5rem',maxWidth:'var(--maxWidth)'}}>{prop.name}</h1>
                </div>
            </div>
        </div>
    );
}

export default PageTitle;