import { Link } from 'react-router-dom';
import teamLogo from '../../images/team-logo.png';
import teamLogo2 from '../../images/team-logo2.png';
import calandarIcon from '../../images/calendar-icon.png';
import locationIcon from '../../images/location-icon.png';
function MobFixtureCard({ data }) {

    const styles = {
        card: {
            width: '100%',
            height: '130px',
            background: 'var(--yellow)',
            borderRadius: '12px',
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            marginBottom: '1rem',
            color: 'white',
            padding: ".5rem",
            boxSizing: 'border-box'
        },
        imageWrapper: {
            width: '80px',
            height: '80px',
            background: 'white',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto'
        },
        TeamsWrapper: {
            display: 'flex',
            width: '100%',
            alignItems: "center",
            whiteSpace: 'nowrap',
            overflow: "hidden"
        },
        btn: {
            textDecoration: 'none',
            font: '600 16px var(--fontSSP)',
            color: 'var(--blue)',
            background: 'white',
            padding: '.4rem 1.7rem',
            borderRadius: '16px'
        }
    };
    return (
        <div style={styles.card}>
            <div style={styles.TeamsWrapper}>
                <div style={{ width: '40%' }}>
                    <div style={styles.imageWrapper}>
                        <img src={data.home_team_logo ? "https://admin.btcluk.com/logo/" + data.home_team_logo : teamLogo} alt="" width="65%" height="65%" />
                    </div>
                    <p style={{ font: '600 1rem var(--fontSSP)', overflow: 'hidden', margin: '8px 0 0 0', textAlign: 'center' }}>{data.home_club_name}</p>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <span style={{ font: 'italic 600 36px var(--fontSSP)' }}>VS</span>
                </div>
                <div style={{ width: '40%' }}>
                    <div style={styles.imageWrapper}>
                        <img src={data.away_team_logo ? "https://admin.btcluk.com/logo/" + data.away_team_logo : teamLogo2} alt="" width="65%" height="65%" />
                    </div>
                    <p style={{ font: '600 1rem var(--fontSSP)', overflow: 'hidden', margin: '8px 0 0 0', textAlign: 'center' }}>{data.away_club_name}</p>
                </div>

            </div>
            {/* <div>
                <div style={{ display: 'flex', marginBottom: '2rem' }}>
                    <span style={{ marginRight: '2rem' }}>
                        <img src={calandarIcon} alt="" />
                    </span>
                    <span style={{ font: '700 1rem var(--fontSSP)' }}>
                        {data.match_date}
                    </span>
                </div>
                <div style={{ display: 'flex' }}>
                    <span style={{ marginRight: '2rem' }}>
                        <img src={locationIcon} alt="" />
                    </span>
                    <span style={{ width: '242px', display: 'inline-block', font: '700 1rem var(--fontSSP)' }}>
                        {data.ground_name}
                    </span>
                </div>
            </div> */}
            {/* <div>
                <Link style={styles.btn}>Get Directions</Link>
            </div> */}
        </div>
    );
}

export default MobFixtureCard;