function AboutBtcl(props) {
    return (

        <div>
            <div id="About-Btcl" className='tabcontent' style={{ padding: '20px', paddingBottom: '2rem' }}>
                <div dangerouslySetInnerHTML={{ __html: props.data }} style={{  font: '400 1rem var(--fontSSP)' }}></div>
            </div>
        </div>
    );
}

export default AboutBtcl;