import share from '../../../images/share-icon.png';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';

function NewsCard({ data }) {
    const navigate = useNavigate();
    const [isHovered2, setIsHovered2] = useState(false);
    const handleHover2 = () => {
        setIsHovered2(true);
      };
      const handleMouseLeave2 = () => {
        setIsHovered2(false);
      };
    const styles = {
        card: {
            boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
            borderRadius: '12px',
            width: '327px',
            height: '400px',
            fontFamily: 'var(--fontSSP)',
            cursor: "pointer"
        },
        title: {
            height: '40px',
            overflow: 'hidden',
            fontSize: '16px',
            fontWeight: 600,
            margin: 0,
            marginBottom: '.5rem'
        },
        caption: {
            fontSize: '16px',
            fontWeight: 400,
            margin: 0,
            height: '40px',
            overflow: 'hidden'
        },
        textWrapper: {
            margin: '1rem 2rem'
        },
        bottomWrapper: {
            display: 'flex',
            margin: '0 1rem 0 1rem',
            justifyContent: 'space-between'
        }
    };
    return (
        <div style={styles.card} onClick={() => navigate('/news/' + data.id)} onMouseLeave={handleMouseLeave2} onMouseEnter={handleHover2}>
            <img src={"https://admin.btcluk.com/files/" + data.image} alt=""  width="327px" height="238px" style={{ borderRadius: '12px 12px 0 0',transition: 'transform 0.3s ease-in-out',
    transform: isHovered2 ? 'scale(1.03)' : 'scale(1)' }} />
            <div style={styles.textWrapper}>
                <h2 style={styles.title}>{data.title}</h2>
                <p style={styles.caption}>{data.content}</p>
            </div>
            <div style={styles.bottomWrapper}>
                <span style={{color:'grey'}}>{data.ago} ago</span>
                <span style={{color:'var(--blue)'}}> Read More...</span>
            </div>
        </div>
    );
}

export default NewsCard;