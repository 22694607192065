import bgImg from '../../images/teamProfileBg.png';
import playerImg from '../../images/teamProfileImg.png';
import mobileLogo from '../../images/mobile-icon.png';
import mailLogo from '../../images/mail-icon.png';
import facebookLogo from '../../images/facebook-icon.png';
import twitterLogo from '../../images/twitter-icon.png';
import youtubeLogo from '../../images/youtube-icon.png';
import instagramLogo from '../../images/instagram-icon.png';

function MobTeamProfileHeader(props) {
    const styles = {
        TeamDtsContainer: {
            width: '100%',
            height: '365px',
            backgroundColor: "var(--yellow)",
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            boxSizing: 'border-box',
            padding: '.5rem'
        },
        imageWrapper: {
            width: '150px',
            height: '150px',
            background: 'white',
            borderRadius: '50%',
            marginBottom:'10px',
            marginLeft:'auto',
            marginRight:'auto'
        },
        TeamPlayerContainer: {
            width: '1232px',
            display: 'grid',
            gridTemplateColumns: 'auto auto auto auto',
            rowGap: '2rem',
            columnGap: '6.5rem',
            justifyContent: 'center',
            margin: 'auto'
        },
        btn: {
            textDecoration: 'none',
            color: 'white',
            background: 'var(--blue)',
            padding: '7px 44px',
            borderRadius: '1rem',
            font: '600 14px var(--fontSSP)',
            width: 'fit-content',
            float: 'center',
            marginLeft:'auto',
            marginRight:'auto'

        }
    };

    return (
        <div style={styles.TeamDtsContainer} id="TeamDetails">
            <div style={{ display: 'block', width: '100%',  alignItems: 'center' ,textAlign:'center'}}>
                <div style={styles.imageWrapper}>
                    <img src={"https://admin.btcluk.com/logo/" + props.data.imglogo} alt="" width="100%" height="100%" style={{ borderRadius: "50%" }} />
                </div>
                <div style={{ color: 'white', display: 'flex', flexFlow: 'column', rowGap: ' 1rem ' }}>
                    <h1 style={{ font: '800 1rem var(--fontInter)', margin: 0 }}>{props.data.name}</h1>
                    <h2 style={{ font: '600 1rem var(--fontInter)', margin: 0 }}>Division :  {props.data.division_name}</h2>
                    <a style={styles.btn} href={props.data.web_link} target='_blank'>website</a>

                    <div>
                        <span><img src={mobileLogo} alt="" /></span>
                        <span> {props.data.contactno}</span>
                    </div>
                    <div>
                        <span><img src={mailLogo} alt="" /></span>
                        <span> {props.data.email}</span>
                    </div>
                    {/* <div style={{ display: 'flex', columnGap: '1rem' }}>
                        <img src={facebookLogo} alt="" />
                        <img src={twitterLogo} alt="" />
                        <img src={youtubeLogo} alt="" />
                        <img src={instagramLogo} alt="" />
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default MobTeamProfileHeader;