import { Link } from 'react-router-dom';
import logo from '../images/logo.png';
import adidasLogo from '../images/adidas-logo.png';
import { HiMenuAlt1 } from 'react-icons/hi';
import { MdClose } from 'react-icons/md';
import { useEffect, useState } from 'react';
import axios from 'axios';
import twitterLogo from '../images/twitter.png';
import youtubeLogo from '../images/youtube.png';
import facebookLogo from '../images/facebook.png';
import instagramLogo from '../images/instagram.png';
import { useTranslation } from '../translation';

function Header() {
    const { t, i18n } = useTranslation();
    const [selectedOption, setSelectedOption] = useState(1);
    const changeLanguage = (event) => {
        let selectedLanguage;
        setSelectedOption(event.target.value);
        localStorage.setItem('language', event.target.value);

        if(event.target.value === '1'){
            selectedLanguage = 'en'
            localStorage.setItem('default_language', 'en');

        }else{
            selectedLanguage = 'ta'
            localStorage.setItem('default_language', 'ta');

        }
        window.location.reload();
        i18n.changeLanguage(selectedLanguage);
      };

    const mobileScreen = 600;
    const [showDiv, setShowDiv] = useState(true);

    const [announcement, setAnnounement] = useState('');
    const [sponsor, setSponsor] = useState('');
    const [sponsorLink, setSponsorLink] = useState('');

    useEffect(() => {
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
          setSelectedOption(storedLanguage);
        }
      }, []);


    const [socialLink, setSocialLink] = useState([]);
    useEffect(() => {
        axios.get("https://admin.btcluk.com/api/socials").then((res) => {
            setSocialLink([res.data.socials.facebook, res.data.socials.twitter, res.data.socials.youtube, res.data.socials.instagram])
        });
    }, [])


  useEffect(() => {
        
        const language = localStorage.getItem('language');
        axios.get("https://admin.btcluk.com/api/announcement/" + language)
            .then((res) => {
                const announce = res.data.announcement
                setAnnounement([announce.text, announce.link]);
            });

        
        axios.get("https://admin.btcluk.com/api/sponsors")
            .then((res) => {
                setSponsor(res.data.sponsors.image);
                setSponsorLink(res.data.sponsors.link);
            });
    }, [selectedOption]);


    useEffect(() => {
        const handleScroll = () => {
          const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    
          if (scrollPosition > 10) {
            setShowDiv(false);
          } else {
            setShowDiv(true);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);


    function openTab(e) {
        let i, tablinks;
        tablinks = document.getElementsByClassName("nav-link");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace("nav-active", "");
        }
        e.currentTarget.className += " nav-active";
        window.scrollTo(0, 0);
    }
    function popup() {
        document.getElementById("hamburger").classList.add("fade-out");
        document.getElementById("hamburger").classList.remove("fade-in");
        document.getElementById("popup-close-btn").classList.add("fade-in");
        document.getElementById('popup-navbar-content').style.right = 0;
        document.getElementById("hamburger").style.display = 'none';
        document.getElementById("popup-close-btn").style.display = 'block';
    }
    function closePopup() {
        document.getElementById('popup-navbar-content').style.right = '-70%';
        document.getElementById("hamburger").classList.add("fade-in");
        document.getElementById("popup-close-btn").classList.remove("fade-in");
        document.getElementById("hamburger").style.display = 'block';
        document.getElementById("popup-close-btn").style.display = 'none';
        document.getElementById("popup-close-btn").classList.add("fade-out");
        window.scrollTo(0, 0);
    }
    function loadVisitedLink(id_txt, cls, id) {
        const collection = document.getElementsByClassName(cls);
        for (let i = 0; i < collection.length; i++) {
            collection[i].style.color = "black";
        }
        document.getElementById(id_txt + id).style.color = '#15CEFF';
        closePopup()
        window.scrollTo(0, 0);
    }
    function mobTabKnockPopup() {
        document.getElementById('mob-tab-knock-popup').style.display = 'block';
        document.getElementById('popup-navbar-content').style.display = 'none';
        document.getElementById("overlay").style.display = "block";
    }
    const style = {
        announcement: {
            whiteSpace: 'nowrap', 
            overflow: 'hidden' 
        },

        announcement_mobile:{
            marginLeft: '0%', 
            display: 'block', 
            color: 'var(--blue)',  
            font: '400 1rem var(--fontSSP)',
            display:'none',
            position:'relative'
        }

    };

    if (window.innerWidth <= mobileScreen) {
        style.announcement.display = 'none';
        style.announcement_mobile.display = '';

    }

 


    return (
        <div style={{ position: 'fixed', left: 0, right: 0, zIndex: 2, top: 0 }}>
            <div style={{ width: '100%', background: 'var(--yellow)' }}>
                <div id="Top-bar">
                    <div id="Tob-entroll-link" style={style.announcement}>
                        <marquee href={announcement[1]} target='_blank' style={{ marginLeft: '0%', display: 'block',fontFamily:"var(--fontInter)", color: 'white', fontSize: 15, fontWeight: 700 }}>{announcement[0]}</marquee>
                    </div>

                    <div id="Tob-log-drop-cont" >
                    <span style={{ font: "700 1rem var(--fontSSP)", color: 'white',whiteSpace:'nowrap' }}>{t('our_proud_sponsor')}</span>

                        <a href={sponsorLink} target='_blank'>
                            <div>
                             <img src={"https://admin.btcluk.com/files/" + sponsor} width="100px" height="30px" />
                            </div>
                        </a>
                        <div>
                            <select name="lang" id="language" value={selectedOption} onChange={changeLanguage} style={{ border: 'none', padding: 1, borderRadius: 5, backgroundColor: 'white', color: "var(--blue)" }}>
                                <option value='1'>EN</option>
                                <option value='2'>TAM</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ background: 'var(--blue)' }}>
                <div id="Nav-bar-wrapper">
                    <ul id="Nav-bar-ul">
                        <li id="Logo-container"><Link to="/"><img src={logo} width='63px' /></Link></li>
                        <li className='nav-link' ></li>
                        <li><Link to="/" className='nav-link nav-active' onClick={openTab}>{t('home')}</Link></li>
                        <li><Link to="/leagues" className='nav-link' onClick={openTab}>{t('divisions')}</Link></li>
                        <li><Link to="/btcl-100-club" className='nav-link' onClick={openTab}>BTCL 100 CLUB</Link></li>
                        {/* <li><Link className='nav-link' onClick={openTab}>CRICKET BASH</Link></li> */}
                        {/* <li><Link className='nav-link' onClick={openTab}>ACADEMY</Link></li> */}
                        <li><Link to="/who-we-are/0" className='nav-link' onClick={openTab}>{t('about_us')}</Link></li>
                        <li><Link to="contact" className='nav-link' onClick={openTab}>{t('contact_us')}</Link></li>
                        <li id="Team-login-btn" className='nav-link' ><a href="https://admin.btcluk.com/team/login" target='_blank' >Team Login</a></li>
                        <li className='nav-link' id="Footer-social-logo-cont">
                        <a href={socialLink[0]} target='_blank'>
                            <span><img src={facebookLogo} /></span>
                        </a>
                        <a href={socialLink[1]} target='_blank'>
                            <span><img src={twitterLogo} /></span>
                        </a>
                        <a href={socialLink[2]} target='_blank'>
                            <span><img src={youtubeLogo} /></span>
                        </a>
                        <a href={socialLink[3]} target='_blank'>
                            <span><img src={instagramLogo} /></span>
                        </a>

                    </li>
                        <li style={{ margin: 0 , marginLeft:'15px' }}><div className="hamburger-container">
                            <span id="popup-close-btn" onClick={closePopup} ><MdClose style={{ width: '30px', height: '30px' }} /></span>
                            <span id="hamburger" onClick={popup} ><HiMenuAlt1 style={{ width: '30px', height: '30px' }} /></span>
                        </div>
                        </li>
                        
                    </ul>
                    {showDiv && <marquee href={announcement[1]} target='_blank' style={style.announcement_mobile}>{announcement[0]}</marquee> }

                    <div className="popup-navbar-content" id="popup-navbar-content">
                        <ul style={{ padding: 0 }}>
                            {/* <li><Link id="tab-mob-nav-link-1" className="tab-mob-nav-link" style={{ color: '#15CEFF' }} to="/" onClick={(e) => loadVisitedLink('tab-mob-nav-link-', 'tab-mob-nav-link', 1)}>HOME</Link></li>
                            <li><Link id="tab-mob-nav-link-2" className="tab-mob-nav-link" to="we-are" onClick={(e) => loadVisitedLink('tab-mob-nav-link-', 'tab-mob-nav-link', 2)}>WE ARE</Link></li>
                            <li><Link id="tab-mob-nav-link-3" className="tab-mob-nav-link" to="services" onClick={(e) => loadVisitedLink('tab-mob-nav-link-', 'tab-mob-nav-link', 3)}>SERVICES</Link></li>
                            <li><Link id="tab-mob-nav-link-4" className="tab-mob-nav-link" to="careers" onClick={(e) => loadVisitedLink('tab-mob-nav-link-', 'tab-mob-nav-link', 4)}>CAREER</Link></li>
                            <li><Link id="tab-mob-nav-link-5" className="tab-mob-nav-link" to="contact" onClick={(e) => loadVisitedLink('tab-mob-nav-link-', 'tab-mob-nav-link', 5)}>CONTACT</Link></li>
                             */}
                            <li><Link to="/" className='mob-nav-link nav-active' onClick={closePopup}>{t('home')}</Link></li>
                            <li><Link to="/leagues" className='mob-nav-link' onClick={closePopup}>{t('divisions')}</Link></li>
                            <li><Link to="/btcl-100-club" className='mob-nav-link' onClick={closePopup}>BTCL 100 CLUB</Link></li>
                            {/* <li><Link className='mob-nav-link' onClick={closePopup}>CRICKET BASH</Link></li> */}
                            {/* <li><Link className='mob-nav-link' onClick={closePopup}>ACADEMY</Link></li> */}
                            <li><Link to="/who-we-are/0" className='mob-nav-link' onClick={closePopup}>{t('about_us')}</Link></li>
                            <li><Link to="contact" className='mob-nav-link' onClick={closePopup}>{t('contact_us')}</Link></li>
                            <li id="Team-login-btn-2"><a href="https://admin.btcluk.com/team/login" target='_blank' className='mob-nav-link' onClick={closePopup}>Team Login</a></li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;