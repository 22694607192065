import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
function ScoreCard() {
    const urls = useParams();
    const [firstBatting, setfirstBatting] = useState('');
    const [secondBatting, setSecondBatting] = useState('');
    const [firstTeam, setFirstTeam] = useState('');
    const [secondTeam, setSecondTeam] = useState('');
    const [groundName, setGroundName] = useState('');
    const [result, setResult] = useState('');
    const [comment, setComment] = useState('');

    const [firstBattingResult, setFirstBattingResult] = useState('');
    const [secondBattingResult, setSecondBattingResult] = useState('');
    const [firstBowlingResult, setFirstBowlingResult] = useState('');
    const [secondBowlingResult, setSecondBowlingResult] = useState('');
    useEffect(() => {
        axios.get("https://admin.btcluk.com/api/scoreCardDetails/" + urls.matchId).then((res) => {
            setfirstBatting(res.data.first_batting_club_name + ' - ' + res.data.first_batting_team_name);
            setSecondBatting(res.data.second_batting_club_name + ' - ' + res.data.second_batting_team_name)
            setResult(res.data.result);
            setComment(res.data.commend);

            
            setGroundName(res.data.groundName)
            const firstTeamData = res.data.first_innings;
            const secondTeamData = res.data.second_innings;
            const firstTeamResult = < tr style={{ height: '40px' }}>
                <td>{firstTeamData.total_extras}</td>
                <td>{firstTeamData.runs}</td>
                <td>{firstTeamData.wickets}</td>
                <td>{firstTeamData.overs}</td>
            </tr>
            const secondTeamResult = < tr style={{ height: '40px' }}>
                <td>{secondTeamData.total_extras}</td>
                <td>{secondTeamData.runs}</td>
                <td>{secondTeamData.wickets}</td>
                <td>{secondTeamData.overs}</td>
            </tr>
            setSecondTeam(secondTeamResult);
            setFirstTeam(firstTeamResult);
            const firstBattingList = res.data.first_batting_batting.map((item, i) => {
                return (
                    <tr key={i}>
                        <td>{item.batsman_name}</td>
                        <td>{item.runs}</td>
                        <td>{item.sixes}</td>
                        <td>{item.fours}</td>
                        <td>{item.balls}</td>
                        <td>{item.how_out}</td>
                    </tr>
                );
            });
            setFirstBattingResult(firstBattingList);
            const firstBowlingList = res.data.first_batting_bowling.map((item, i) => {
                return (
                    <tr key={i}>
                        <td>{item.bowler_name}</td>
                        <td>{item.wickets}</td>
                        <td>{item.overs}</td>
                        <td>{item.maidens}</td>
                        <td>{item.runs}</td>
                        <td>{item.wides}</td>
                        <td>{item.no_balls}</td>
                    </tr>
                );
            });
            setFirstBowlingResult(firstBowlingList);
            const secondBattingList = res.data.second_batting_batting.map((item, i) => {
                return (
                    <tr key={i}>
                        <td>{item.batsman_name}</td>
                        <td>{item.runs}</td>
                        <td>{item.sixes}</td>
                        <td>{item.fours}</td>
                        <td>{item.balls}</td>
                        <td>{item.how_out}</td>
                    </tr>
                );
            });
            setSecondBattingResult(secondBattingList);
            const secondBowlingList = res.data.second_batting_bowling.map((item, i) => {
                return (
                    <tr key={i}>
                        <td>{item.bowler_name}</td>
                        <td>{item.wickets}</td>
                        <td>{item.overs}</td>
                        <td>{item.maidens}</td>
                        <td>{item.runs}</td>
                        <td>{item.wides}</td>
                        <td>{item.no_balls}</td>
                    </tr>
                );
            });
            setSecondBowlingResult(secondBowlingList);
            document.getElementById('score-card-loader').style.display = 'none';
        });
    }, []);

    const statTable = {
        width: '100%',
        borderCollapse: 'collapse',
        marginTop: '1rem',

    };

    return (
        <div id="score-card" style={{ maxWidth: 'var(--maxWidth)', margin: ' 1rem auto'  }}>
            <div className="loader" id="score-card-loader" style={{ margin: 'auto' }}></div>
            <div style={{ font: '700 1rem var(--fontSSP)' ,textAlign:'center',borderRadius:'10px', padding:'1px',backgroundColor:'var(--yellow)'}}>
                <h2>{firstBatting} <span style={{ color: 'White', fontSize: '2rem' }}>Vs</span> {secondBatting}</h2>
                <h3>{groundName}</h3>
                <h1 style={{color:'green'}} > {result} </h1>

            </div>

            {comment && (
            <div style={{ font: '700 1rem var(--fontSSP)', padding:'0.1rem'  , borderRadius:'10px',textAlign:'center'}}>
            <h3 style={{color:'red',font: '700 1rem var(--fontSSP)' ,}}> {comment}</h3>
            </div>
            )}


            <div style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)' }}>
                <h1 style={{ background: 'var(--blue)', color: 'white', font: '700 24px var(--fontSSP)', height: '40px', padding: '10px', borderTopLeftRadius:'10px',borderTopRightRadius:'10px' }}> {firstBatting}</h1>
                <table style={statTable}>
                    <thead>
                        <tr>
                            <th>Extras</th>
                            <th>Runs</th>
                            <th>Wickets</th>
                            <th>Overs</th>
                        </tr>
                    </thead>
                    <tbody>
                        {firstTeam}
                    </tbody>
                </table>
            </div>
            <h1 style={{ background: 'var(--blue)', color: 'white', font: '500 20px var(--fontSSP)', height: '5px', padding: '20px' , borderTopLeftRadius:'10px',borderTopRightRadius:'10px'}}>Batting Scores </h1>
            <div style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)', padding: '10px' }}>
                <table style={statTable}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Runs</th>
                            <th>6s</th>
                            <th>4s</th>
                            <th>Balls</th>
                            <th>How out</th>
                        </tr>
                    </thead>
                    <tbody>
                        {firstBattingResult}
                    </tbody>
                </table>
            </div>
            <h1 style={{ background: 'var(--blue)', color: 'white', font: '500 20px var(--fontSSP)', height: '5px', padding: '20px', borderTopLeftRadius:'10px',borderTopRightRadius:'10px'}}>Bowling Scores</h1>
            <div style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)', padding: '10px' }}>
                <table style={statTable}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Wts</th>
                            <th>Overs</th>
                            <th>Maidens</th>
                            <th>Runs</th>
                            <th>wides</th>
                            <th>No Balls</th>
                        </tr>
                    </thead>
                    <tbody>
                        {firstBowlingResult}
                    </tbody>
                </table>
            </div>
            <div style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)' }}>
                <h1 style={{ background: 'var(--blue)', color: 'white', font: '700 24px var(--fontSSP)', height: '40px', padding: '10px', borderTopLeftRadius:'10px',borderTopRightRadius:'10px' }}>{secondBatting}</h1>
                <table style={statTable}>
                    <thead>
                        <tr>
                            <th>Extras</th>
                            <th>Runs</th>
                            <th>Wickets</th>
                            <th>Overs</th>
                        </tr>
                    </thead>
                    <tbody>
                        {secondTeam}
                    </tbody>
                </table>
            </div>
            <h1 style={{ background: 'var(--blue)', color: 'white', font: '500 20px var(--fontSSP)', height: '5px', padding: '20px', borderTopLeftRadius:'10px',borderTopRightRadius:'10px' }}>Batting Scores</h1>

            <div style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)', padding: '20px' }}>
                <table style={statTable}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Runs</th>
                            <th>6s</th>
                            <th>4s</th>
                            <th>Balls</th>
                            <th>How out</th>
                        </tr>
                    </thead>
                    <tbody>
                        {secondBattingResult}
                    </tbody>
                </table>
            </div>
            <h1 style={{ background: 'var(--blue)', color: 'white', font: '500 20px var(--fontSSP)', height: '5px', padding: '20px' , borderTopLeftRadius:'10px',borderTopRightRadius:'10px'}}>Bowling Scores</h1>
            <div style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)', padding: '20px' }}>
                <table style={statTable}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Wts</th>
                            <th>Overs</th>
                            <th>Maidens</th>
                            <th>Runs</th>
                            <th>wides</th>
                            <th>No Balls</th>
                        </tr>
                    </thead>
                    <tbody>
                        {secondBowlingResult}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ScoreCard;