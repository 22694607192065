import { Link } from 'react-router-dom';
import Slider from "./Slider";
import { useEffect, useState } from 'react';
import axios from 'axios';
export default function Hero() {
    const [banner1, setBanner1] = useState([]);
    const [banner2, setBanner2] = useState([]);
    const [socialLink, setSocialLink] = useState([]);
    const [banner1Link, setBanner1Link] = useState('');
    const [banner2Link, setBanner2Link] = useState('');

    useEffect(() => {
        axios.get("https://admin.btcluk.com/api/banner_1/1").then((res) => {
            const banner = res.data.Banner_1;
            setBanner1Link(banner.link);
            setBanner1([banner.title, banner.sub_text, banner.image])
        });
        axios.get("https://admin.btcluk.com/api/banner_2/1").then((res) => {
            const banner = res.data.Banner_2;
            setBanner2Link(banner.link);
            setBanner2([banner.title, banner.sub_text, banner.image])
        });
        axios.get("https://admin.btcluk.com/api/socials").then((res) => {
            setSocialLink([res.data.socials.facebook, res.data.socials.twitter, res.data.socials.youtube, res.data.socials.instagram])
        });

    }, []);
    const [isHovered1, setIsHovered1] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);

    const styles = {
        wrapper: {
            display: "flex",
            columnGap: "32px",
            height: "calc(100% - 30px)"
        },
        one: {
            height: '50%', 
            position: 'relative' ,
            transition: 'transform 0.3s ease-in-out',
    transform: isHovered1 ? 'scale(1.1)' : 'scale(1)',
        },
        two: {
            height: '50%', 
            position: 'relative' ,
            transition: 'transform 0.3s ease-in-out',
    transform: isHovered2 ? 'scale(1.05)' : 'scale(1)',
        },
        sideSectionContainer: {
            display: "flex",
            flexFlow: 'column',
            rowGap: "24px",
            flexShrink: 0,
            overflow: 'hidden',
            
        }
    }
      
        const handleHover1 = () => {
          setIsHovered1(true);
        };
      
        const handleMouseLeave1 = () => {
          setIsHovered1(false);
        };
        const handleHover2 = () => {
            setIsHovered2(true);
          };
        
          const handleMouseLeave2 = () => {
            setIsHovered2(false);
          };
    return (
        <div id='hero-container'>
            <div id='hero' style={styles.wrapper}>
                <Slider />
                <div id="side-banner-container" style={styles.sideSectionContainer}  onMouseEnter={handleHover1} onMouseLeave={handleMouseLeave1}>
                    <div style={styles.one}  onMouseEnter={handleHover1} onMouseLeave={handleMouseLeave1}>
                        <a href={banner1Link} target='_blank'>
                            <img src={"https://admin.btcluk.com/files/" + banner1[2]} style={{ position: 'relative' }} alt="" width="100%" height="100%" />
                        </a>
                        <div style={{ color: 'white', position: 'absolute', zIndex: 1, bottom: 0, padding: ".5rem" }}>
                            <p style={{ font: "700 20px var(--fontSSP)", margin: 0, width: '50%' }}>{banner1[0]}</p>
                            <p style={{ font: "400 1remp var(--fontSSP)", margin: 0 }}>{banner1[1]}</p>
                        </div>
                    </div>
                    <div style={styles.two}  onMouseEnter={handleHover2} onMouseLeave={handleMouseLeave2}>
                        <a href={banner2Link} target='_blank'>
                            <img src={"https://admin.btcluk.com/files/" + banner2[2]} style={{ position: 'relative' }} alt="" width="100%" height="100%" />
                        </a>
                        <div style={{ color: 'white', position: 'absolute', zIndex: 1, bottom: ' 1rem', padding: ".5rem" }}>
                            <p style={{ font: "700 20px var(--fontSSP)", margin: 0, width: '50%' }}>{banner2[0]}</p>
                            <p style={{ font: "400 1rem var(--fontSSP)", margin: 0 }}> {banner2[1]}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: "10px" }}>
                <div id="social-link-wrapper" style={{ display: 'flex', justifyContent: 'center' }}>
                    <a href={socialLink[0]} target='_blank' style={{ color: '#3B5998', textDecoration: 'none' }}>
                        <span className='social-link' >Facebook</span>
                    </a>
                    <a href={socialLink[1]} target='_blank' style={{ color: '#00ACEE', textDecoration: 'none' }}>
                        <span className='social-link'>Twitter</span>
                    </a>
                    <a href={socialLink[2]} target='_blank' style={{ color: '#C4302B', textDecoration: 'none' }}>
                        <span className='social-link'>Youtube</span>
                    </a>
                    <a href={socialLink[3]} target='_blank' style={{ color: '#8A060D', textDecoration: 'none' }}>
                        <span className='social-link'>Istagram</span>

                    </a>
                </div>

            </div>
        </div>
    );
}